// @flow
import React, { Children, useState, forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import css from '@emotion/css/macro';
import { Link } from 'react-router-dom';
import { Tooltip } from '@quid/react-tooltip';
import ErrorTooltip from 'components/ErrorTooltip';
import Color from 'color';

export const Branch = styled.div`
  border-left-width: ${props => (props.disabled && props.last ? 0 : 1)}px;
  border-bottom-width: ${props => (props.disabled ? 0 : 1)}px;
  border-color: ${props => props.theme.colors.gray1};
  border-style: solid;
  transform: translateY(-50%);
  height: 30px;
  width: 40px;
  margin-right: 10px;
`;

const linkStyle = props => css`
  color: ${props.theme.link};
  text-decoration: underline;
  cursor: pointer;
`;

const NonLink = ({ innerRef, ...props }) => (
  <span {...props} ref={innerRef} tabIndex={0} />
);
const LinkAnchor = styled(
  forwardRef(({ showError, ...props }, ref) => {
    const Tag = showError ? NonLink : Link;
    return <Tag {...props} innerRef={ref} />;
  })
)`
  ${props =>
    props.showError
      ? css`
          color: ${Color(props.theme.colors.red)
            .fade(0.3)
            .toString()};
          cursor: help;
          text-decoration: underline;
          &:focus:not(:focus-visible) {
            outline: none;
          }
          &:hover,
          &:focus {
            text-decoration: none;
            color: ${props.theme.colors.black};
          }
        `
      : linkStyle(props)}
`;

const LinkButton = styled.button`
  all: unset;
  ${linkStyle}
`;

export const Element = styled(
  ({ to, onClick, disabled, last, error, children, ...props }) => (
    <li {...props}>
      <Branch disabled={disabled} last={last} />

      {disabled ? null : to ? (
        <Tooltip
          renderTooltip={props => (
            <ErrorTooltip {...props}>{error}</ErrorTooltip>
          )}
        >
          {({ ref, open, close }) => (
            <LinkAnchor
              to={to}
              data-value={to}
              ref={ref}
              onMouseEnter={error ? open : undefined}
              onFocus={error ? open : undefined}
              onBlur={error ? close : undefined}
              showError={error != null}
            >
              {children}
            </LinkAnchor>
          )}
        </Tooltip>
      ) : onClick ? (
        <LinkButton onClick={onClick}>{children}</LinkButton>
      ) : (
        children
      )}
    </li>
  )
)`
  display: flex;
  align-items: center;
  height: 30px;
`;

type Props = {
  limit: number,
  list: Array<{ to?: string, label: string, error: ?string }>,
};

const FileTreeList = styled(({ list, limit = 6, ...props }: Props) => {
  const overflows = list.length > limit;
  const linksLimit = overflows ? limit - 2 : limit;

  const [offset, setOffset] = useState(0);

  const prev = overflows ? (
    <Element onClick={() => setOffset(offset - 1)} disabled={offset === 0}>
      …
    </Element>
  ) : null;

  const next = overflows ? (
    <Element
      onClick={() => setOffset(offset + 1)}
      disabled={offset === list.length - linksLimit}
      last
    >
      …
    </Element>
  ) : null;

  return (
    <ul {...props}>
      {Children.toArray([
        prev,
        ...list
          .slice(offset, offset + linksLimit)
          .map(({ to, label, error }, key) => (
            <Element key={key} to={to} error={error}>
              {label}
            </Element>
          )),
        next,
      ])}
    </ul>
  );
})`
  padding-top: 15px;
`;

export default FileTreeList;
