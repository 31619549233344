// @flow
import { Component, type Node } from 'react';

type Props = {
  children: Node,
  delay?: number,
};

type State = {
  render: boolean,
};

export default class DelayRendering extends Component<Props, State> {
  static defaultProps = {
    delay: 200,
  };

  timeoutId: TimeoutID;

  state = {
    render: false,
  };

  componentDidMount() {
    this.timeoutId = setTimeout(
      () => this.setState({ render: true }),
      this.props.delay
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    return this.state.render ? this.props.children : null;
  }
}
