// @flow
import { BasicPanel as Panel } from '@quid-private/react-components';
import { colors } from '@quid/theme';
import Color from 'color';
import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';

const shadowBlack = a =>
  Color(colors.black)
    .alpha(a)
    .string();

const Container = styled(Panel, { shouldForwardProp: isPropValid })`
  ${props => props.isPublic && `background-color: #F8F8F8`};
  box-shadow: 0 2px 2px 0 ${shadowBlack(0.2)};
  border: 1px solid var(--gray1);
  border-radius: 4px;
  transition: box-shadow 0.2s ease-in-out;
  width: 26em;

  &:hover {
    box-shadow: 0 3px 6px 0 ${shadowBlack(0.2)},
      0 3px 6px 0 ${shadowBlack(0.16)};
  }
`;

export default Container;
