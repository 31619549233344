// @flow
// From https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md
import React, { Component } from 'react';
import { InfiniteLoader, Grid } from 'react-virtualized';

type Props = {
  columnCount: number,
  width: number,
  height: number,
  // flowlint-next-line unclear-type:warn
  infiniteLoaderProps: Object,
};

export default class InfiniteLoaderGrid extends Component<Props> {
  onRowsRendered = null;

  infiniteLoaderChildFunction = ({
    onRowsRendered,
    registerChild,
  }: {
    // flowlint-next-line unclear-type:warn
    onRowsRendered: Function,
    // flowlint-next-line unclear-type:warn
    registerChild: Function,
  }) => {
    this.onRowsRendered = onRowsRendered;

    const {
      // eslint-disable-next-line no-unused-vars
      infiniteLoaderProps,
      ...gridProps
    } = this.props;

    return (
      <Grid
        {...gridProps}
        onSectionRendered={this.onSectionRendered}
        ref={registerChild}
      />
    );
  };

  onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }: {
    columnStartIndex: number,
    columnStopIndex: number,
    rowStartIndex: number,
    rowStopIndex: number,
  }) => {
    const columnCount = this.props.columnCount;
    const startIndex = rowStartIndex * columnCount + columnStartIndex;
    const stopIndex = rowStopIndex * columnCount + columnStopIndex;

    this.onRowsRendered &&
      this.onRowsRendered({
        startIndex,
        stopIndex,
      });
  };

  render() {
    const { infiniteLoaderProps } = this.props;

    if (this.props.width <= 0 || this.props.height <= 0) {
      return null;
    }

    return (
      <InfiniteLoader {...infiniteLoaderProps}>
        {this.infiniteLoaderChildFunction}
      </InfiniteLoader>
    );
  }
}
