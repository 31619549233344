// @flow
import React from 'react';

export type FlashMessageContextValue = {
  flashMessages: Array<{ message: string, id: string }>,
  setFlashMessage: (args: string) => void,
};

export const FlashMessageContext = React.createContext<FlashMessageContextValue>(
  {
    flashMessages: [],
    setFlashMessage() {},
  }
);
