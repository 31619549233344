//@flow
import styled from '@emotion/styled/macro';

const AppContainer = styled.div`
  height: 100%;
  margin: 0;
  overflow: hidden;
  min-width: 800px;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.colors.gray7};
  transition: background-color 0.2s ease-in-out;
`;

export default AppContainer;
