//@flow
import React, { Children, Fragment } from 'react';
import { Text, Icon } from '@quid/react-core';
import styled from '@emotion/styled/macro';
import { useQuery } from '@apollo/react-hooks';
import { toDateString } from 'utils/date';
import { resolveFilterLabel } from 'utils/analysis';
import { GET_ANALYSIS_QUERY } from 'modules/queries';

type Props = {
  analysisId: string,
};

type AnalysisIntent = {
  id: string,
  analysis: {
    // flowlint-next-line unclear-type:warn
    intent: Object,
  },
};

const Section = styled.section`
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const InputDetailsIcon = styled(Icon)`
  margin-right: 5px;
`;

const Title = styled(Text)`
  margin-right: 5px;
`;

const Spacer = styled.span`
  padding: 0 5px;
  display: inline-block;
`;

const Phrase = styled(Text)`
  display: inline-block;
`;

type InputDetailsListProps = {
  list: Array<{ text: string }>,
};

export const InputDetailsList = ({ list }: InputDetailsListProps) => {
  return Children.toArray(
    list
      // eslint-disable-next-line react/jsx-key
      .map(({ text }, i) => [
        <Phrase>{text}</Phrase>,
        i < list.length - 1 && <Spacer>•</Spacer>,
      ])
      .reduce((acc, [a, b]) => [...acc, a, b], [])
  );
};

const InputDetails = ({ analysisId }: Props) => {
  const { data: { analysis } = {}, loading } = useQuery<AnalysisIntent>(
    GET_ANALYSIS_QUERY,
    {
      variables: {
        analysisId: analysisId,
      },
    }
  );

  if (analysis && analysis.intent && !loading) {
    const intent = analysis.intent;
    return (
      <Fragment>
        <Section>
          <Title type="bold">
            <InputDetailsIcon name="calendar" />
            Period:
          </Title>
          <Text>
            {`From ${toDateString(intent.period.from, {
              month: 'long',
            })} to ${toDateString(intent.period.to, { month: 'long' })}`}
          </Text>
        </Section>
        {intent.companies && intent.companies.length ? (
          <Section>
            <Title type="bold">
              <InputDetailsIcon name="building" />
              Companies:
            </Title>
            <InputDetailsList list={intent.companies} />
          </Section>
        ) : null}
        {intent.competitors && intent.competitors.length ? (
          <Section>
            <Title type="bold">
              <InputDetailsIcon name="bolt" />
              Competitors:
            </Title>
            <InputDetailsList list={intent.competitors} />
          </Section>
        ) : null}
        {intent.topics && intent.topics.length ? (
          <Section>
            <Title type="bold">
              <InputDetailsIcon name="comment_o" />
              Topics:
            </Title>
            <InputDetailsList list={intent.topics} />
          </Section>
        ) : null}
        {intent.andTopics && intent.andTopics.length ? (
          <Section>
            <Title type="bold">
              <InputDetailsIcon name="commenting_o" />
              Narrow Topics:
            </Title>
            <InputDetailsList list={intent.andTopics} />
          </Section>
        ) : null}

        {intent.filters && intent.filters.length ? (
          <Section>
            <Title type="bold">
              <InputDetailsIcon name="filter" />
              Filters:
            </Title>
            <InputDetailsList
              list={intent.filters.map(filter => ({
                text: resolveFilterLabel(filter),
              }))}
            />
          </Section>
        ) : null}
      </Fragment>
    );
  } else {
    return (
      <Text.Skeleton>Loading awesome text about Input Details.</Text.Skeleton>
    );
  }
};

export default InputDetails;
