// @flow
import React from 'react';
import { Route } from 'react-router-dom';
import { Button, Text } from '@quid/react-core';
import useRouter from 'use-react-router';
import styled from '@emotion/styled/macro';
import GenericScaffhold, { Container, Side, Main } from 'components/Scaffhold';
import AnalysisCard from 'components/AnalysisCard';
import Navbar from 'components/Navbar';
import UsersList from 'components/UsersList';
import ProjectTitle from 'components/ProjectTitle';
import { useIntegrationContext } from 'components/IntegrationContext';
import { toDateString } from 'utils/date';
import { analysesUrl } from 'utils/url';
import { GET_PROJECT_ANALYSES_QUERY } from './queries';
import {
  DELETE_ANALYSIS_MUTATION,
  deleteAnalysisUpdater,
  deleteAnalysisOptimistic,
} from './mutations/deleteAnalysis';
import useUpdateAnalysisSchedule, {
  type UpdateFactoryArgs,
  type ApolloCache,
} from './mutations/updateAnalysisSchedule';
import DeleteModal from 'components/DeleteModal';
import { viewOutputUrl } from 'utils/url';
import type { Analysis as TAnalysis, Run as TRun } from 'types/Analysis';
import type { User } from 'types/User';
import type { Project } from 'types/Project';
import useAnalyticsContext from 'utils/useAnalyticsContext';
import {
  canCreate,
  canSchedule,
  canDelete,
} from 'modules/authorization/analyses';
import { STATE_COMPLETED, STATE_ERROR } from 'modules/analyses';
import BREAKPOINT from 'utils/breakpoint';

// Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

const Scaffhold = styled(GenericScaffhold)`
  height: 100vh;
`;
const SharedBox = styled.section`
  flex: 1;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    margin-left: ${props => props.theme.sizes.large};
    min-width: 40%;
  }
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.56em 0.74em 0.56em 2.79em;
  border-bottom: 1px solid var(--gray1);
`;
const HeaderMainActionButton = styled(Button)`
  margin-left: auto;
`;
const List = styled.div`
  overflow: auto;
  flex: 1;
  padding: ${props => props.theme.sizes.large};
`;
const Title = styled(ProjectTitle)`
  margin-bottom: 0.93em;
`;
const Spacer = styled.div`
  height: 0.46em;
`;

export function isAnalysisSuccessful(analysis: TAnalysis) {
  const itHasBundles = Boolean(analysis.outputs && analysis.outputs.length > 0);
  const mostRecentRun =
    analysis.runs &&
    analysis.runs.sort(
      (a, b) => new Date(b.startedAt) - new Date(a.startedAt)
    )[0];
  const isMostRecentRunCompleted =
    mostRecentRun && mostRecentRun.state === STATE_COMPLETED;
  const isImportedAnalysis = analysis.type === 'IMPORT';

  // Return `true` only if at least a bundle is available and the analysis
  // most recent run state is `COMPLETED` or analysis type is "IMPORT"
  // the latter case is needed because imported analyses don't have runs
  return itHasBundles && (isImportedAnalysis || isMostRecentRunCompleted);
}

export const getErrorMessage = (run: TRun) => {
  if (run.state !== STATE_ERROR) {
    return null;
  }
  switch (run.stateInfo && run.stateInfo.code) {
    case 'INSUFFICIENT_DOCUMENTS':
      return "We weren't able to collect enough documents to generate an analysis for your request. Please broaden your inputs or time range and try again.";
    default:
      return 'An unexpected error occurred while generating your analysis.';
  }
};

type AnalysisProps = {
  analysis: TAnalysis,
  projectId: string,
  canDeleteAnalysis: boolean,
  canScheduleAnalysis: boolean,
  canAccessSchedule: boolean,
  canView?: boolean,
  updateAnalysisScheduleFactory?: UpdateFactoryArgs => ApolloCache => void,
};

const Analysis = ({
  analysis,
  projectId,
  canDeleteAnalysis,
  canScheduleAnalysis,
  canAccessSchedule,
  canView,
  updateAnalysisScheduleFactory,
}: AnalysisProps) => {
  const [updateSchedule] = useUpdateAnalysisSchedule({
    projectId,
    analysis,
    updateAnalysisScheduleFactory,
  });

  return (
    <AnalysisCard
      id={analysis.id}
      data-context="analysis"
      type={analysis.type}
      title={analysis.title}
      createdAt={analysis.timestamps.createdAt}
      topics={
        analysis.intent.topics && analysis.intent.topics.map(({ text }) => text)
      }
      competitors={
        analysis.intent.competitors &&
        analysis.intent.competitors.map(({ text }) => text)
      }
      timeRange={[analysis.intent.period.from, analysis.intent.period.to]}
      viewLink={
        isAnalysisSuccessful(analysis)
          ? viewOutputUrl({
              projectId: projectId,
              analysisId: analysis.id,
              outputId: String(analysis.outputs && analysis.outputs[0].id),
            })
          : undefined
      }
      canDelete={canDeleteAnalysis}
      canSchedule={canScheduleAnalysis}
      canAccessSchedule={canAccessSchedule}
      canView={canView}
      schedule={analysis.schedule}
      updateSchedule={updateSchedule}
      list={
        analysis.runs
          ? analysis.runs.map(run => ({
              to: run.output
                ? viewOutputUrl({
                    projectId,
                    analysisId: analysis.id,
                    outputId: run.output.id,
                  })
                : null,
              label: toDateString(run.startedAt),
              error: getErrorMessage(run),
            }))
          : []
      }
    />
  );
};

const AnalysisList = ({
  dataReady,
  analyses,
  projectId,
  owner,
  me,
  canDeleteAnalysis,
  canScheduleAnalysis,
  canAccessSchedule,
}: {
  dataReady: boolean,
  analyses: Array<TAnalysis>,
  projectId: string,
  owner: User,
  me: User,
  canDeleteAnalysis: boolean,
  canScheduleAnalysis: boolean,
  canAccessSchedule: boolean,
}) => {
  return (
    <List data-context="analysis-list">
      {!dataReady ? (
        Array.from({ length: 5 }, (_, key) => (
          <AnalysisCard.Skeleton key={key} />
        ))
      ) : analyses.length ? (
        analyses.map(analysis => (
          <Analysis
            key={analysis.id}
            analysis={analysis}
            projectId={projectId}
            canDeleteAnalysis={canDeleteAnalysis}
            canScheduleAnalysis={canScheduleAnalysis}
            canAccessSchedule={canAccessSchedule}
          />
        ))
      ) : (
        <Text as="p" type="secondary">
          You don't have any analyses yet.
        </Text>
      )}
    </List>
  );
};

const SideBar = ({
  dataReady,
  project,
  owner,
}: {
  dataReady: boolean,
  project: Project,
  owner: User,
}) => (
  <Side data-context="sidebar">
    <section>
      <Title data-context="title">
        {dataReady ? (
          `Project ${project.name}`
        ) : (
          <Text.Skeleton>projectname</Text.Skeleton>
        )}
      </Title>
      {dataReady ? (
        <Text as="p" type="secondary" data-context="last-edited">
          Last edited {toDateString(project.timestamps.updatedAt)}
        </Text>
      ) : (
        <Text.Skeleton as="p" type="secondary" data-context="last-edited">
          -------
        </Text.Skeleton>
      )}
      <Spacer />
      {owner &&
        (dataReady ? (
          <Text as="p" type="secondary" data-context="created">
            Created {toDateString(project.timestamps.createdAt)}
            <Text type="secondary" data-context="createdBy" data-pii="true">
              {` by ${owner.fullName}`}
            </Text>
          </Text>
        ) : (
          <Text.Skeleton as="p" type="secondary" data-context="last-edited">
            ------------
          </Text.Skeleton>
        ))}
    </section>
    {dataReady && !!project.sharedWith.length && (
      <SharedBox data-context="shared-with">
        <Text as="p" type="secondary" data-context="shared-count">
          Shared with {project.sharedWith.length} users:
        </Text>
        <UsersList users={project.sharedWith} data-context="shared-users" />
      </SharedBox>
    )}
  </Side>
);

export const DeleteAnalysisModal = ({
  projectId,
  analysis,
}: {
  projectId: string,
  analysis: TAnalysis,
}) => {
  const { history } = useRouter();
  const [deleteAnalysis] = useMutation(DELETE_ANALYSIS_MUTATION, {
    variables: { id: analysis.id },
    update: deleteAnalysisUpdater(projectId, analysis.id),
    optimisticResponse: deleteAnalysisOptimistic(analysis),
  });

  return (
    <DeleteModal
      title="Delete Analysis"
      data-context="delete-analysis-modal"
      isOpen
      onCancel={() => history.push(analysesUrl({ projectId }))}
      onDelete={() => {
        deleteAnalysis();
        history.push(analysesUrl({ projectId }));
      }}
    >
      <Text as="h1" type="large">
        Are you sure you want to delete the selected analysis?
      </Text>
      <Text as="p" type="secondary">
        Deleting the analysis will also delete all saved views for you and those
        shared on this project.The delete action is permanent.
      </Text>
    </DeleteModal>
  );
};

export const Analyses = () => {
  useAnalyticsContext();
  const { LaunchDarkly } = useIntegrationContext();
  const {
    match: { path, url, params },
  } = useRouter();

  const projectId = String(params.projectId);

  const { data = {}, loading, error } = useQuery(GET_PROJECT_ANALYSES_QUERY, {
    variables: {
      projectId,
    },
  });

  const { project, me } = data;
  const analyses = project ? project.analyses.items : [];
  const owner = project ? project.owner : {};
  const dataReady = !loading && !error && project;

  const canCreateAnalysis = dataReady && canCreate({ project, me });
  const canDeleteAnalysis = dataReady && canDelete({ project, me });
  const canAccessSchedule = LaunchDarkly.flag('apps-scheduler', false);
  const canScheduleAnalysis = dataReady && canSchedule({ project, me });

  return (
    <Scaffhold data-context="analyses-page">
      <Route
        path={`${path}/:analysisId/delete`}
        children={({ match }) => {
          const analysis =
            match && analyses.find(a => a.id === match.params.analysisId);
          return analysis ? (
            <DeleteAnalysisModal projectId={projectId} analysis={analysis} />
          ) : null;
        }}
      />

      <Navbar type="APPS" data-context="top-nav" />
      <Container>
        <SideBar dataReady={dataReady} project={project} owner={owner} />
        <Main data-context="main">
          {canCreateAnalysis && (
            <Header>
              <HeaderMainActionButton
                to={`${url}/new`}
                importance="primary"
                data-action="open-create-analysis"
              >
                New Analysis
              </HeaderMainActionButton>
            </Header>
          )}
          <AnalysisList
            dataReady={dataReady}
            analyses={analyses}
            projectId={projectId}
            owner={owner}
            me={me}
            canDeleteAnalysis={canDeleteAnalysis}
            canScheduleAnalysis={canScheduleAnalysis}
            canAccessSchedule={canAccessSchedule}
          />
        </Main>
      </Container>
    </Scaffhold>
  );
};

export default Analyses;
