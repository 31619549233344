// @flow
import React from 'react';
import useRouter from 'use-react-router';
import { Button } from '@quid/react-core';
import { ThemeProvider } from '@quid/theme';
import { Modal as SpinnerModal } from 'components/SpinnerModal';
import AppContainer from './AppContainer';
import ScreenContainer from './ScreenContainer';

function BackButton(props) {
  return (
    <Button {...props} importance="secondary">
      Back
    </Button>
  );
}

export type Props = {
  message: string,
};

export default function Loading({ message }: Props) {
  const {
    history: { goBack },
  } = useRouter();
  return (
    <ThemeProvider theme="dark">
      <AppContainer>
        <ScreenContainer>
          <SpinnerModal
            isOpen
            renderActionRight={actionClassName => (
              <BackButton onClick={goBack} className={actionClassName} />
            )}
            message={message}
          />
        </ScreenContainer>
      </AppContainer>
    </ThemeProvider>
  );
}
