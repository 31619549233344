// @flow
import gql from 'graphql-tag';

const meQuery = `
  me {
    id
    fullName
    email
    idpAuthentication
    roles {
      name
    }
  }
`;

const insightFields = gql`
  fragment insightFields on Insight {
    selectedVertices
    shownVertices
    layout {
      id
      state
      legend {
        type
      }
    }
    title
    subtitle
    summary
    body {
      text
      url
    }
    type
    grouping {
      field
      groups {
        name
        values
      }
    }
    data {
      id
      dataType
    }
    entities {
      name
      items {
        id
        title
        body
        dbIDs
        url
      }
    }
    description
    detailsEntity
    selection {
      selectedCount
      totalCount
      item
    }
    ... on EventInsight {
      period {
        from
        to
      }
    }
  }
`;

export const insightGroupFields = gql`
  fragment insightGroupFields on InsightGroup {
    id
    name
    description
    items {
      id
      ...insightFields
    }
  }
  ${insightFields}
`;

export const bundleFields = gql`
  fragment bundleFields on Bundle {
    id
    score
    title
    summary
    data {
      id
      dataType
    }
    insights {
      ...insightGroupFields
    }
    layouts {
      id
      state
    }
  }
  ${insightGroupFields}
`;

export const getBundle = gql`
  query getBundle($id: ID!) {
    bundle(id: $id) {
      ...bundleFields
    }
  }
  ${bundleFields}
`;

export const entity = gql`
  fragment entity on Entity {
    text
    type
    field
    autocompleted
  }
`;
export const kolDebugFragment = gql`
  fragment kolDebugFragment on KOLIntent {
    topics {
      ...entity
    }
    andTopics {
      ...entity
    }
    filters
    geoFilters {
      countries
      type
    }
    period {
      from
      to
    }
  }
  ${entity}
`;
export const headlinesDebugFragment = gql`
  fragment headlinesDebugFragment on HeadlinesIntent {
    topics {
      ...entity
    }
    andTopics {
      ...entity
    }
    filters
    geoFilters {
      countries
      type
    }
    period {
      from
      to
    }
  }
  ${entity}
`;
export const competitiveAnalysisIntentDebugFragment = gql`
  fragment competitiveAnalysisIntentDebugFragment on CompetitiveIntent {
    companies {
      ...entity
    }
    competitors {
      ...entity
    }
    topics {
      ...entity
    }
    filters
    geoFilters {
      countries
      type
    }
    period {
      from
      to
    }
  }
  ${entity}
`;

export const contentWhitespaceIntentDebugFragment = gql`
  fragment contentWhitespaceIntentDebugFragment on ContentWhitespaceIntent {
    topics {
      ...entity
    }
    andTopics {
      ...entity
    }
    filters
    geoFilters {
      countries
      type
    }
    period {
      from
      to
    }
  }
  ${entity}
`;

export const getBundlesFromOutput = gql`
  query getBundlesFromOutput($id: ID!) {
    output(id: $id) {
      bundles(sortType: SCORE) {
        items {
          ...bundleFields
        }
      }
      analysis {
        title
        type
        intent {
          ...kolDebugFragment
          ...competitiveAnalysisIntentDebugFragment
          ...contentWhitespaceIntentDebugFragment
          ...headlinesDebugFragment
        }
        project {
          id
        }
      }
    }
    ${meQuery}
  }
  ${bundleFields}
  ${kolDebugFragment}
  ${headlinesDebugFragment}
  ${competitiveAnalysisIntentDebugFragment}
  ${contentWhitespaceIntentDebugFragment}
`;

export const getBundleFromOutput = gql`
  query getBundleFromOutput($outputId: ID!, $id: ID!) {
    bundle(outputId: $outputId, id: $id) {
      ...bundleFields
    }
  }
  ${bundleFields}
`;

export const getInsight = gql`
  query getInsight($id: ID!) {
    insight(id: $id) {
      id
      ...insightFields
    }
  }
  ${insightFields}
`;

export const getInsightsFromBundle = gql`
  query getInsightsFromBundle($outputId: ID!, $id: ID!) {
    bundle(outputId: $outputId, id: $id) {
      ...bundleFields
    }
  }
  ${bundleFields}
`;

export const getProjectAndAnalysisTitle = gql`
  query getProjectAndAnalysisTitle(
    $projectId: ID!
    $outputId: ID!
    $hasProjectId: Boolean!
    $hasOutputId: Boolean!
  ) {
    project(id: $projectId, type: APPS) @include(if: $hasProjectId) {
      id # Workaround for https://github.com/apollographql/react-apollo/issues/1314#issuecomment-374773268
      name
    }
    output(id: $outputId) @include(if: $hasOutputId) {
      analysis {
        type
        project {
          id
        }
        title
      }
    }
  }
`;

export const getProjectAndPortfolioTitle = gql`
  query getProjectAndPortfolioTitle(
    $projectId: ID!
    $portfolioId: ID!
    $hasProjectId: Boolean!
    $hasPortfolioId: Boolean!
  ) {
    project(id: $projectId, type: PRO) @include(if: $hasProjectId) {
      id # Workaround for https://github.com/apollographql/react-apollo/issues/1314#issuecomment-374773268
      name
    }
    portfolio(id: $portfolioId) @include(if: $hasPortfolioId) {
      id # Workaround for https://github.com/apollographql/react-apollo/issues/1314#issuecomment-374773268
      title
    }
  }
`;

export const getVisualizationData = gql`
  query getVisualizationData($id: ID!, $type: DataType!) {
    visualizationData(id: $id, type: $type) {
      id
      dataType
      nodes {
        items
      }
      schema
      ... on NetworkWithSchemaData {
        links {
          items {
            source
            target
            weight
          }
        }
        clusterings {
          items {
            id
            name
            communities
          }
        }
      }
      ... on Network {
        links {
          items {
            source
            target
            weight
          }
        }
        clusterings {
          items {
            id
            name
            communities
          }
        }
      }
    }
  }
`;

export const getMe = gql`
  query getMe {
    ${meQuery}
  }
`;

export const GET_ANALYSIS_QUERY = gql`
  query getAnalysis($analysisId: ID!) {
    analysis(id: $analysisId) {
      id
      title
      type
      timestamps {
        createdAt
      }
      title
      intent {
        ... on CompetitiveIntent {
          topics {
            text
          }
          competitors {
            text
          }
          companies {
            text
          }
          filters
        }
        ... on ContentWhitespaceIntent {
          topics {
            text
          }

          andTopics {
            text
          }
          filters
        }
        ... on HeadlinesIntent {
          topics {
            text
          }

          andTopics {
            text
          }
          filters
        }
        ... on KOLIntent {
          topics {
            text
          }
          andTopics {
            text
          }
          filters
        }
        period {
          from
          to
        }
      }
      outputs {
        id
      }
      runs {
        state
        startedAt
      }
    }
  }
`;

export const getEntities = gql`
  query getData($inputs: [DataInput!]) {
    data(inputs: $inputs) {
      id
      ... on NewsArticle {
        cleanBody(start: 0, end: 200)
      }
    }
  }
`;
