// @flow
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';
import { Icon, Text } from '@quid/react-core';
import { Tooltip } from '@quid/react-tooltip';
import { Breadcrumb as QRBreadcrumb } from '@quid/react-layouts';
import { type ContentProps } from '@quid/react-layouts/dist/Breadcrumb';
import { Logo as RCLogo } from '@quid-private/react-components';
import useRouter from 'use-react-router';
import { projectUrl, viewOutputUrl } from 'utils/url';
import {
  getProjectAndAnalysisTitle,
  getProjectAndPortfolioTitle,
} from 'modules/queries';
import { getLabelFromAnalysisType } from 'utils/analysis';
import type { Analysis } from 'types/Analysis';
import type { Project } from 'types/Project';
import Ellipsis from 'components/Ellipsis';
import TextSkeleton from 'components/TextSkeleton';
import TooltipContainer from 'components/TooltipContainer';
import { getQuidProUrl } from 'modules/util';
import AppSelector from './AppSelector';

const ProjectName = styled(Ellipsis)`
  max-width: 280px;
  display: inline-block;
  margin-top: 5px;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const Logo = styled(RCLogo)`
  margin-bottom: -8px;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${props => props.theme.colors.gray5};
  transform: rotate(30deg);
  margin: 0 15px;
`;

const IconicBreadcrumbItem = styled(({ name, tooltip, alt, ...props }) => (
  <Tooltip
    openDelay={200}
    renderTooltip={props => (
      <TooltipContainer {...props}>{tooltip}</TooltipContainer>
    )}
  >
    {({ ref, open }) => (
      <div onMouseEnter={open} ref={ref} {...props}>
        <Icon name={name} alt={alt} />
      </div>
    )}
  </Tooltip>
))`
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const renderArrowIcon = () => <Separator />;

export type BreadcrumbItem = {|
  label: React.Node,
  path: string,
  arrowIcon?: string,
  disabled?: boolean,
  external?: boolean,
  emphasized?: boolean,
  tooltip?: string,
|};

export const composeAnalysisTitle = (
  analysis: Analysis,
  prefix: boolean = true
) =>
  analysis &&
  `${prefix ? 'ANALYZE: ' : ''}${[
    getLabelFromAnalysisType(analysis.type),
    analysis.title,
  ]
    .filter(Boolean)
    .join(' - ')}`;

export type BreadcrumbItemsProps = {
  projectId?: ?string,
  analysisId?: ?string,
  outputId?: ?string,
  project?: Project,
  output?: { analysis: Analysis },
  type?: 'APPS' | 'PRO',
};

const queries = {
  APPS: getProjectAndAnalysisTitle,
  PRO: getProjectAndPortfolioTitle,
};

export type GenerateBreadcrumbItems = BreadcrumbItemsProps => Array<BreadcrumbItem>;

export function defaultGenerateBreadcrumbItems({
  projectId,
  analysisId,
  outputId,
  project,
  output,
  type,
}: BreadcrumbItemsProps): Array<BreadcrumbItem> {
  const projectTitleText = output
    ? composeAnalysisTitle(output.analysis, false)
    : null;

  return ([
    {
      label: <Logo />,
      path: getQuidProUrl(),
      external: true,
      renderArrowIcon,
    },
    !!type &&
      (type === 'APPS'
        ? {
            path: '/apps/projects',
            label: 'Apps',
            renderContent: ({ path }: ContentProps) => (
              <AppSelector path={path} />
            ),
            renderArrowIcon,
          }
        : {
            renderContent: () => (
              <Text type="bold" as="a" href={getQuidProUrl()}>
                Pro
              </Text>
            ),
            renderArrowIcon,
          }),
    projectId != null && {
      label: (
        <IconicBreadcrumbItem
          id="tooltip-project-folder"
          tooltip={project ? project.name : 'Project'}
          alt={project ? project.name : 'Project'}
          delay={0}
          name="folder"
        />
      ),
      path: projectUrl({ projectId, type }),
      renderArrowIcon,
    },
    outputId != null && {
      label: (
        <ProjectName title={projectTitleText}>
          {projectTitleText ? projectTitleText : <TextSkeleton width="100px" />}
        </ProjectName>
      ),
      path:
        projectId != null && analysisId != null && outputId != null
          ? viewOutputUrl({
              projectId,
              analysisId,
              outputId,
            })
          : '',
      renderArrowIcon,
    },
    // flowlint-next-line unclear-type:warn
  ].filter(Boolean): Array<Object>);
}

type Props = {
  generateBreadcrumbItems?: GenerateBreadcrumbItems,
  type?: 'APPS' | 'PRO',
};

const Breadcrumb = styled(
  ({
    generateBreadcrumbItems = defaultGenerateBreadcrumbItems,
    type,
    ...props
  }: Props) => {
    const {
      match: {
        params: { projectId, analysisId, outputId, portfolioId },
      },
    } = useRouter();

    const hasProjectId = Boolean(projectId);
    const hasOutputId = Boolean(outputId);
    const hasPortfolioId = Boolean(portfolioId);

    const query = type ? queries[type] : queries['PRO'];

    const { data: { project, output } = {} } = useQuery(query, {
      skip: hasProjectId === false && hasOutputId === false,
      variables: {
        projectId: projectId || 'null',
        outputId: outputId || 'null',
        portfolioId: portfolioId || 'null',
        hasProjectId,
        hasOutputId,
        hasPortfolioId,
      },
    });

    return (
      <QRBreadcrumb
        items={generateBreadcrumbItems({
          projectId,
          analysisId,
          outputId,
          project,
          output,
          type,
        })}
        {...props}
      />
    );
  }
)`
  align-items: center;
  margin-left: -10px;
`;

export default Breadcrumb;
