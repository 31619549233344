// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';

// Probably the ugliest solution the world will ever see, but with this
// we can automatically strip any trailing slash from all the app's paths
export default function RemoveTrailingSlash() {
  if (
    window.location.pathname.length > 1 &&
    window.location.pathname.substr(-1) === '/'
  ) {
    return (
      <Redirect
        to={window.location.pathname.substr(
          0,
          window.location.pathname.length - 1
        )}
      />
    );
  } else {
    return null;
  }
}
