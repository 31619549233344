// @flow
import React from 'react';
import { Modal } from '@quid/react-layouts';
import { Button } from '@quid/react-core';

type Props = {
  children?: React$Node,
  // flowlint-next-line unclear-type:warn
  onDelete: Function,
  // flowlint-next-line unclear-type:warn
  onCancel: Function,
  title: string,
  isOpen: boolean,
};

const DeleteModal = ({
  children,
  onDelete,
  onCancel,
  title,
  isOpen,
  ...rest
}: Props) => {
  return (
    <Modal
      title={title}
      data-context="delete-modal"
      isOpen={isOpen}
      importance="warning"
      renderActionRight={actionClassName => [
        <Button
          key="cancel"
          onClick={onCancel}
          data-action="cancel"
          className={actionClassName}
        >
          Cancel
        </Button>,
        <Button
          key="delete"
          importance="primary"
          onClick={onDelete}
          data-action="delete"
          className={actionClassName}
        >
          Delete
        </Button>,
      ]}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default DeleteModal;
