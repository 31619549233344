// @flow
import React, { type, Node } from 'react';
import styled from '@emotion/styled/macro';
import { Button, Icon } from '@quid/react-core';
import useLocalStorage from 'utils/useLocalStorage';
import BREAKPOINT from 'utils/breakpoint';

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.black};
  height: ${props => (props.open ? 150 : 0)}px;
  opacity: ${props => (props.open ? 1 : 0)};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  padding: 0 92px;
  @media (max-width: ${BREAKPOINT.NETBOOK}px) {
    padding: 0 10px;
  }
  @media (max-width: ${BREAKPOINT.TABLET}px) {
    padding: 10px 0;
    flex-direction: column;
    height: ${props => (props.open ? 260 : 0)}px;
  }
`;

const CaptionContainer = styled.div`
  width: 700px;
`;

const ButtonContainer = styled.div`
  padding-top: 5px;
`;

const BannerButton = styled(Button)`
  height: 43px;
  border-radius: 10px;
  font-size: 26px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.color};
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.pink};
  }
  &:focus {
    color: ${props => props.theme.colors.white};
  }
`;

const CallToAction = styled(({ className, text, href, to, title, color }) => (
  <ButtonContainer className={className}>
    <BannerButton
      importance="primary"
      href={href}
      to={to}
      target="_blank"
      rel="noopener"
      title={title}
      color={color}
    >
      {text}
    </BannerButton>
  </ButtonContainer>
))`
  position: relative;
  white-space: nowrap;
`;

const CloseButton = styled(props => (
  <Button transparent {...props}>
    <Icon name="close" />
  </Button>
))`
  position: absolute;
  top: ${props => props.theme.sizes.small};
  right: ${props => props.theme.sizes.small};
  width: 25px;
  padding: 0 5px;
  font-size: 20px;
`;

type Props = {
  caption: Node,
  href?: string,
  to?: string,
  callToAction: string,
  callToActionColor: string,
  bannerId: string,
  logo: Node,
};
const Banner = ({
  caption,
  href,
  to,
  callToAction,
  callToActionColor,
  bannerId,
  logo,
}: Props) => {
  const [isOpen, setOpen] = useLocalStorage(`show-banner-${bannerId}`, true);
  return (
    <Container open={isOpen}>
      {logo}
      <CaptionContainer>{caption}</CaptionContainer>
      <CallToAction
        href={href}
        to={to}
        text={callToAction}
        title={caption}
        color={callToActionColor}
      />
      <CloseButton onClick={() => setOpen(false)} />
    </Container>
  );
};

export default Banner;
