// @flow
import type { Analysis } from 'types/Analysis';

export const STATE_SUBMITTED: 'SUBMITTED' = 'SUBMITTED';
export const STATE_COMPLETED: 'COMPLETED' = 'COMPLETED';
export const STATE_ERROR: 'ERROR' = 'ERROR';

const getLast = <T>(array: Array<T>): T => array.slice(-1)[0];

export const getCompletedAnalysisOutputId = ({
  runs,
  outputs,
}: Analysis): ?string => {
  // give priority to the latest run's output
  if (runs && runs.length) {
    const { output, state } = getLast(runs);
    if (output && state === 'COMPLETED') {
      return output.id;
    }
  }

  // fallback to the latest output
  if (outputs) {
    const latestOutput = getLast(outputs);
    if (latestOutput) {
      return latestOutput.id;
    }
  }

  return undefined;
};

export const getLatestAnalysisRunId = ({ runs }: Analysis): ?string => {
  if (runs && runs.length) {
    return getLast(runs).id;
  }
  return undefined;
};
