// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Text, Button, Icon } from '@quid/react-core';
import { toDateString } from 'utils/date';
import ProjectTitle from 'components/ProjectTitle';
import { OwnedFooter, SharedFooter, BaseFooter } from './Footer';
import Skeleton from './Skeleton';
import Container from './Container';
import Content from './Content';
import ribbon from './ribbon.svg';
import pluralize from 'pluralize';

import type { User } from 'types/User';

type Props = {
  title: string,
  subEntitiesCount: number,
  subEntitiesLabel: string,
  lastEditedAt: Date,
  sharedWith: Array<?User>,
  ownerName: ?string,
  canModify: boolean,
  canShare: boolean,
  isPublic: boolean,
  showPublicProjects: boolean,
  onOpenShareManager: () => void,
  onDelete: SyntheticEvent<HTMLElement> => void,
  to: string,
};

const Title = styled(ProjectTitle)`
  flex: 1;
  min-height: 0;
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Ribbon = styled.img`
  width: 20px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 12px;
`;

const DeleteButton = props => (
  <Button data-action="open-delete-project" {...props}>
    <Icon name="trash" />
  </Button>
);

const ProjectCard = ({
  title,
  subEntitiesCount,
  subEntitiesLabel,
  lastEditedAt,
  sharedWith = [],
  onOpenShareManager,
  ownerName,
  canModify,
  canShare,
  isPublic,
  showPublicProjects,
  onDelete,
  to,
  ...props
}: Props) => {
  if (!showPublicProjects && isPublic) {
    return false;
  }
  return (
    <Container
      data-context="project-card"
      data-public={isPublic}
      isPublic={isPublic}
      data-value={title.trim()}
    >
      {isPublic && <Ribbon src={ribbon} alt="" />}
      <Content to={to} data-action="view-project">
        <Title type="bold xlarge" data-context="title">
          {title}
        </Title>
        <ContentFooter>
          <div>
            <Text as="p" type="secondary" data-context="count">
              {subEntitiesCount} {pluralize(subEntitiesLabel, subEntitiesCount)}
            </Text>
            <Text as="p" type="secondary" data-context="last-edited">
              Last edited {toDateString(lastEditedAt)}
            </Text>
          </div>
          <div>{canModify && <DeleteButton onClick={onDelete} />}</div>
        </ContentFooter>
      </Content>
      {canModify && canShare && (
        <OwnedFooter
          sharedWith={sharedWith}
          onOpenShareManager={onOpenShareManager}
        />
      )}
      {!canModify && ownerName && <SharedFooter sharedBy={ownerName} />}
      {isPublic && <BaseFooter text="Examples of Quid Apps" />}
      {!isPublic && !canShare && <BaseFooter />}
    </Container>
  );
};

ProjectCard.Skeleton = Skeleton;

export default ProjectCard;
