// @flow
import React from 'react';
import Loading from '../Loading';
import { NotFound } from '../ErrorMessage';
import { Redirect } from 'react-router-dom';
import type { Match } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_PROJECT_ID = gql`
  query getProjectId($analysisId: ID!) {
    analysis(id: $analysisId) {
      id
      project {
        id
      }
    }
  }
`;

const AnalysisUnsubscribe = ({ match }: { match: Match }) => {
  const { data: { analysis } = {}, loading, error } = useQuery(GET_PROJECT_ID, {
    variables: {
      analysisId: match.params.analysisId,
    },
  });

  if (loading) {
    return <Loading message="Redirecting to your analysis settings" />;
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <Redirect
      to={`/apps/projects/${String(analysis.project.id)}/analyses/${String(
        analysis.id
      )}/unsubscribe`}
    />
  );
};

export default AnalysisUnsubscribe;
