// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Text as RCText } from '@quid/react-core';
import { getColorByType } from 'utils/color';
import type { AnalysisTypes } from 'types/AnalysisTypes';
import { getLabelFromAnalysisType } from 'utils/analysis';

const Container = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  text-align: left;
  background-color: ${props => props.backgroundColor};
`;

const Text = styled(RCText)`
  text-align: left;
  color: ${props => props.theme.colors.black};
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: normal;
  text-transform: uppercase;
`;

type Props = {
  analysisType?: AnalysisTypes,
  isLoading?: boolean,
  // flowlint-next-line unclear-type:warn
  textProps?: Object,
};
const defaultTextProps = {
  as: 'p',
  'data-context': 'type-label',
};

const Tag = ({
  analysisType,
  isLoading = false,
  textProps = {},
  ...props
}: Props) => (
  <Container
    backgroundColor={
      analysisType ? getColorByType(analysisType) : 'transparent'
    }
    {...props}
  >
    {isLoading ? (
      <RCText.Skeleton
        type="secondary"
        {...{ ...defaultTextProps, ...textProps }}
      >
        ----------------
      </RCText.Skeleton>
    ) : (
      <Text {...{ ...defaultTextProps, ...textProps }}>
        {analysisType && getLabelFromAnalysisType(analysisType)}
      </Text>
    )}
  </Container>
);

export default Tag;
