// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import MacCoffeTasksIcon from './MacCoffeTasksIcon';
import { Text, Button } from '@quid/react-core';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4.28em 0;
`;

const StyledButton = styled(Button)`
  margin: 0 10px;
`;

const TextHolder = styled.div`
  margin: 2.14em 0;
  text-align: center;
`;

const Title = styled(Text)`
  margin-bottom: 0.65em;
`;

const EmptyProject = () => (
  <Container>
    <MacCoffeTasksIcon />
    <TextHolder>
      <Title type="xlarge bold" as="h1">
        Create Your First Project With Quid
      </Title>
      <Text as="h3">
        Create a new project to start your first analysis in Quid.
      </Text>
    </TextHolder>
    <div>
      <StyledButton
        href="https://resources.quid.com/hc/en-us/articles/360011966654"
        target="_blank"
      >
        Learn More
      </StyledButton>
      <StyledButton to="/apps/projects/new" importance="primary">
        Create Project
      </StyledButton>
    </div>
  </Container>
);

export default EmptyProject;
