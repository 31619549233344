// @flow
import gql from 'graphql-tag';
import { GET_PROJECT_ANALYSES_QUERY } from '../queries';
import { resolveTypename } from 'utils/analysis';
import type { Analysis } from 'types/Analysis';

export const DELETE_ANALYSIS_MUTATION = gql`
  mutation deleteAnalysis($id: ID!) {
    deleteAnalysis(id: $id) {
      id
    }
  }
`;

export const deleteAnalysisUpdater = (
  projectId: string,
  analysisId: string
// flowlint-next-line unclear-type:warn
) => (cache: Object) => {
  const query = GET_PROJECT_ANALYSES_QUERY;
  const data = cache.readQuery({
    query,
    variables: { projectId },
  });

  const updatedAnalyses = {
    ...data.project.analyses,
    totalCount: data.project.analyses.items.length - 1,
    items: data.project.analyses.items.filter(a => a.id !== analysisId),
  };

  const newData = {
    ...data,
    project: {
      ...data.project,
      analyses: updatedAnalyses,
    },
  };

  cache.writeQuery({ query, data: newData });
};

export const deleteAnalysisOptimistic = (analysis: Analysis) => ({
  __typename: 'Mutation',
  deleteAnalysis: {
    __typename: resolveTypename(analysis.type),
    id: analysis.id,
  },
});
