// @flow
import React from 'react';
import { Logo as RCLogo } from '@quid-private/react-components';
import { Modal as QRModal } from '@quid/react-layouts';
import styled from '@emotion/styled/macro';

const Logo = styled(RCLogo)`
  height: 2.04em;
  width: auto;
  vertical-align: middle;
`;

const Content = styled.div`
  text-align: center;
`;

export type Props = {
  children: React$Node,
  innerClassName?: string,
};

function Modal({ children, innerClassName, ...props }: Props) {
  return (
    <QRModal
      importance="action"
      alt="Quid"
      title={<Logo noDefaultStyle color="black" />}
      {...props}
    >
      <Content className={innerClassName}>{children}</Content>
    </QRModal>
  );
}

export default Modal;
