//@flow

import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { getMe } from 'modules/queries';

const canAccessEntity = (definedRoles, accessedRoles) => {
  return accessedRoles
    .map(role => role.name)
    .some(role => definedRoles.includes(role));
};

export const PRO_ROLES = [
  'pro_news_orgs_patents',
  'novas',
  'opus',
  'social_media',
  'social_media_explorer',
  'external_project_tracking',
];
export const ADMIN_ROLES = ['admin'];
export const APPS_ROLES = [
  'core_apps',
  'apps_freemium_trial_user',
  'apps_limited_trial_user',
];

const useAccessControl = () => {
  const [hasAppsAccess, setAppsAccess] = useState(false);
  const [hasProAccess, setProAccess] = useState(false);
  const [hasAdminAccess, setAdminAccess] = useState(false);
  const [isRequestLoading, setRequestLoading] = useState(true);

  const { loading, data = {} } = useQuery(getMe);

  useEffect(() => {
    if (!loading && data.me) {
      setProAccess(canAccessEntity(PRO_ROLES, data.me.roles));
      setAppsAccess(canAccessEntity(APPS_ROLES, data.me.roles));
      setAdminAccess(canAccessEntity(ADMIN_ROLES, data.me.roles));
    }
    setRequestLoading(loading);
  }, [loading, data]);

  return {
    loadingUserAccess: isRequestLoading,
    hasAppsAccess,
    hasProAccess,
    hasAdminAccess,
  };
};
export default useAccessControl;
