// @flow
import type { AnalysisTypes } from 'types/AnalysisTypes';
import { colors } from '@quid/theme';

export const ANALYSIS_TYPES = {
  COMPETITIVE: {
    typename: 'CompetitiveAnalysis',
    label: 'Competitor Briefing',
    color: colors.rose,
  },
  CONTENT_WHITESPACE: {
    typename: 'ContentWhitespace',
    label: 'Content Strategy',
    color: colors.blue,
  },
  KOL: {
    typename: 'KOL',
    label: 'Key Opinion Leaders',
    color: colors.lime,
  },
  HEADLINES: {
    typename: 'HEADLINES',
    label: 'Topic Tracker',
    color: colors.aqua,
  },
  // Note, we do not officially support apps below
  HR_COMPETITIVE: {
    typename: 'HR_COMPETITIVE',
    label: 'HR Competitive',
    color: colors.tan,
  },
  EXECUTIVE_BRIEFING: {
    typename: 'ExecutiveBriefing',
    label: 'Executive Briefing',
    color: colors.orange,
  },
  PATIENT_JOURNEY_SEGMENTATION: {
    typename: 'PatientJourney',
    label: 'Patient Journey',
    color: colors.rose,
  },
  CUSTOM: {
    typename: 'CustomAnalysis',
    label: 'Custom',
    color: colors.coral,
  },
  IMPORT: {
    typename: 'ImportAnalysis',
    label: 'Import',
    color: colors.lemon,
  },
  STRATEGIC_PARTNERSHIPS: {
    typename: 'StrategicPartnerships',
    label: 'Strategic Partnerships',
    color: colors.lilac,
  },
  KOL_BIOMEDICAL_RESEARCH: {
    typename: 'KolBiomedicalResearch',
    label: 'Kol Biomedical Research',
    color: colors.peach,
  },
};

type FilterTypes = {
  [string]: {
    label: string,
  },
};

export const FILTER_TYPES: FilterTypes = {
  US: {
    label: 'United States',
  },
  GB: {
    label: 'United Kingdom',
  },
  CA: {
    label: 'Canada',
  },
  IN: {
    label: 'India',
  },
  AU: {
    label: 'Australia',
  },
  AFRICA: {
    label: 'Africa',
  },
  EUROPE: {
    label: 'Europe',
  },
  LATIN: {
    label: 'Latin America',
  },
  APAC: {
    label: 'Asia Pacific',
  },
  INCLUDE_OTHERS: {
    label: 'All other countries',
  },
  PRESS_RELEASES: {
    label: 'Press releases',
  },
  FINANCIAL_NEWS: {
    label: 'Financial news',
  },
  EARNINGS_CALLS: {
    label: 'Earning calls',
  },
  STOCK_NEWS: {
    label: 'Stock news',
  },
};

export function resolveFilterLabel(filterType: string): string {
  return FILTER_TYPES[filterType] ? FILTER_TYPES[filterType].label : filterType;
}

export function resolveTypename(type: AnalysisTypes): string {
  return ANALYSIS_TYPES[type].typename;
}

export function getLabelFromAnalysisType(type: AnalysisTypes): string {
  // Provide a fallback to at least show some kind of useful info
  return ANALYSIS_TYPES[type] ? ANALYSIS_TYPES[type].label : type;
}
