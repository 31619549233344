// @flow

// Please, do not fill this file with stuff, this file
// should stay clean and short
import React from 'react';
import ReactDOM from 'react-dom';
import axe from 'react-axe-es';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from 'modules/apollo';
import { IntegrationProvider } from 'components/IntegrationContext';
import { ServiceWorkerProvider } from 'components/ServiceWorkerContext';
import { initialize } from 'utils/debugContext';
import { apocalypseHandler } from './apocalypseHandler';

//
// Global stylesheets
//
import '@quid-private/quid-styles/css/index.css'; // quid-styles
import 'reset-css/reset.css';
import './index.css';
import '@quid-private/react-components/dist/index.css'; // react-components stylesheets

// Polyfills
import 'array-flat-polyfill';

// what-input helps us to show accessible UIs to keyboard users
import 'what-input';

// App component
import App from './screens/App';

apocalypseHandler();

// This is used to run accessibility checks at runtime
if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_AXE === 'true'
) {
  axe(React, ReactDOM, 1000);
}

// This object is used to store some references to internal instances
// we want to make easily accessible when we debug the app on non-tech people computers
initialize();

function render(App, rootElement) {
  ReactDOM.render(
    <ServiceWorkerProvider>
      <ApolloProvider client={apolloClient}>
        <IntegrationProvider>
          <App />
        </IntegrationProvider>
      </ApolloProvider>
    </ServiceWorkerProvider>,
    rootElement
  );
}

const rootElement = document.getElementById('root');
if (!(rootElement instanceof Element)) {
  throw new Error('#root element not found');
}

render(App, rootElement);

// Enable almost-true hot-reloading of our components
// $FlowFixMe
if (module.hot) {
  module.hot.accept('./screens/App', async () => {
    const { default: NextApp } = await import('./screens/App');
    render(NextApp, rootElement);
  });
}
