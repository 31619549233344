// @flow
import React, { lazy, Suspense } from 'react';
import DelayRendering from 'components/DelayRendering';
import { Text } from '@quid/react-core';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';

const Admin = lazy(() => import('./Admin'));

const Fallback = () => (
  <DelayRendering>
    <Scaffhold>
      <Navbar
        type="APPS"
        displayName={<Text.Skeleton>fullname</Text.Skeleton>}
      />
      <Container>
        <Main />
      </Container>
    </Scaffhold>
  </DelayRendering>
);

export default () => (
  <Suspense fallback={<Fallback />}>
    <Admin />
  </Suspense>
);
