// @flow

export const isInitialized = () => {
  return Boolean(window.__QUID_DEBUG__);
};

export const isSet = (key: string) => {
  if (!isInitialized()) {
    return false;
  }
  return window.__QUID_DEBUG__.hasOwnProperty(key);
};

export const initialize = () => {
  if (isInitialized()) {
    // eslint-disable-next-line no-console
    return console.error(
      '__QUID_DEBUG__ is already set, you may have tried to call this method twice'
    );
  }
  window.__QUID_DEBUG__ = {};
  return window.__QUID_DEBUG__;
};

// flowlint-next-line unclear-type:warn
export const registerProperty = (key: string, value: any) => {
  if (!isInitialized()) {
    window.__QUID_DEBUG__ = {};
  }
  window.__QUID_DEBUG__[key] = value;
};

export const unregisterProperty = (key: string) => {
  if (!isInitialized()) {
    // eslint-disable-next-line no-console
    return console.error(
      '__QUID_DEBUG__ is not set, you may have tried to call this method before initializing the debug context'
    );
  }
  delete window.__QUID_DEBUG__[key];
};
