// @flow
import React from 'react';
import quidLiveLogo from './quid-live-2020.svg';
import Banner from './index';
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';

const BannerPink = '#D8127E';

const Live2020LogoContainer = styled.div`
  height: 90px;
  width: 284px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src})`};
`;

const BannerText = styled(Text)`
  font-size: 23px;
`;

const ColoredText = styled(BannerText)`
  color: ${BannerPink};
`;

const Caption = styled(({ className }) => (
  <div className={className}>
    <BannerText>
      Enter the NEW World of Consumer and Market Intelligence
      <p>
        <strong>
          {' '}
          <ColoredText>NORTH AMERICA</ColoredText> NOVEMBER 10-12 |{' '}
          <ColoredText>EMEA</ColoredText> NOVEMBER 17-18
        </strong>
      </p>
    </BannerText>
  </div>
))`
  margin-bottom: 10px;
  text-align: center;
`;

const Live2020Banner = () => (
  <Banner
    bannerId="netbase-quid-live-2020"
    caption={<Caption />}
    href="https://netbasequidlive.com/"
    callToAction="See Agenda"
    callToActionColor={BannerPink}
    logo={
      <Live2020LogoContainer src={quidLiveLogo} alt="NetBase Quid Live 2020" />
    }
  />
);

export default Live2020Banner;
