// @flow
import gql from 'graphql-tag';

export const GET_ANALYSIS_AND_ME_QUERY = gql`
  query getAnalysisAndMe($analysisId: ID!) {
    me {
      id
    }
    analysis(id: $analysisId) {
      id
      title
      type
      timestamps {
        createdAt
      }
      title
      schedule {
        enabled
        frequency {
          amount
          period
        }
      }
      intent {
        ... on CompetitiveIntent {
          topics {
            text
          }
          competitors {
            text
          }
          companies {
            text
          }
          filters
        }
        ... on ContentWhitespaceIntent {
          topics {
            text
          }

          andTopics {
            text
          }
          filters
        }
        ... on HeadlinesIntent {
          topics {
            text
          }

          andTopics {
            text
          }
          filters
        }
        ... on KOLIntent {
          topics {
            text
          }
          andTopics {
            text
          }
          filters
        }
        period {
          from
          to
        }
      }
      outputs {
        id
      }
      project {
        ... on AppsProject {
          id # Workaround for https://github.com/apollographql/react-apollo/issues/1314#issuecomment-374773268
          public
          owner {
            id
          }
        }
      }
      runs {
        state
        startedAt
        scheduled
        output {
          id
        }
      }
    }
  }
`;
