// @flow
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';
import { themes, colors } from '@quid/theme';
const TextSkeleton = styled(Text.Skeleton)`
  // FIXME: this should be on Refraction
  line-height: initial;
`;
TextSkeleton.defaultProps = {
  theme: { ...themes.dark, gray7: colors.gray6 },
};

export default TextSkeleton;
