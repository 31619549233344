// @flow
import React from 'react';
import { Text, Button } from '@quid/react-core';
import styled from '@emotion/styled/macro';
import UseCaseCard from './UseCaseCard';
import BREAKPOINT from 'utils/breakpoint';

const MIN_WIDTH = 570;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: ${MIN_WIDTH}px;
  @media (max-width: ${BREAKPOINT.NETBOOK}px) {
    min-width: 0;
  }
  @media (max-width: ${BREAKPOINT.TABLET}px) {
    flex: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${props => props.productImage});
  background-repeat: no-repeat;
  background-position: 8% 11%;
  background-size: 116px 170px;
  min-height: 170px;
  flex-shrink: 0;
  margin: 30px 0;
`;

const SubContainer = styled.div`
  width: ${MIN_WIDTH}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  @media (max-width: ${BREAKPOINT.NETBOOK}px) {
    width: auto;
  }
`;

const Title = styled(Text)`
  font-size: 34px;
  line-height: 0.66;
  color: ${props => props.theme.colors.white};
  margin: 15px 0 15px 0;
  z-index: 2;
`;

const Description = styled(Text)`
  text-align: left;
  font-size: 20px;
  line-height: 1.4;
  z-index: 2;
`;

Description.defaultProps = { type: 'xlarge regular' };

const HorizontalDivider = styled.div`
  width: 40px;
  height: 4px;
  background-color: ${props => props.theme.colors.gray5};
  margin-bottom: 5px;
`;

const QuestionText = styled.div``;

const UseCasesContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  list-style: none;
`;

const LaunchButton = styled(Button)`
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;

const StyledSection = styled.section`
  flex-shrink: 0;
`;

type UseCase = {
  title: string,
  summary: string,
  comingSoon?: boolean,
};

type ProductSectionProps = {
  isLoading: boolean,
  productTitle: string,
  productDescription: string,
  useCases: Array<UseCase>,
  hasAccess: boolean,
  hasAccessURL?: string,
  hasAccessLink?: string,
  hasAccessText: string,
  noAccessURL: string,
  noAccessText: string,
  productImage: string,
};

const ProductSection = ({
  isLoading,
  productTitle,
  productDescription,
  useCases,
  hasAccess,
  hasAccessURL,
  hasAccessLink,
  hasAccessText,
  noAccessURL,
  noAccessText,
  productImage,
  ...props
}: ProductSectionProps) => {
  let buttonText;
  let buttonLink;
  if (isLoading) {
    buttonText = hasAccessText;
  } else {
    buttonText = hasAccess ? hasAccessText : noAccessText;
    buttonLink = hasAccess ? hasAccessURL || hasAccessLink : noAccessURL;
  }

  const useLink = hasAccessLink != null && hasAccess;

  return (
    <Container>
      <SubContainer>
        <Header productImage={productImage}>
          <Title as="h1" type="xlarge bold title">
            {productTitle}
          </Title>
          <Description as="h2">{productDescription}</Description>
        </Header>
        <LaunchButton
          data-action={'go-' + productTitle.toLowerCase()}
          importance="primary"
          disabled={isLoading}
          to={useLink && buttonLink}
          href={!useLink && buttonLink}
        >
          {buttonText}
        </LaunchButton>
        <StyledSection>
          <div style={{ margin: '30px 0 20px' }}>
            <HorizontalDivider />
            <QuestionText>
              <Description as="h2">What is in {productTitle}?</Description>
            </QuestionText>
          </div>
          <UseCasesContainer>
            {useCases.map((useCase, index) => (
              <UseCaseCard
                key={index}
                title={useCase.title}
                summary={useCase.summary}
                comingSoon={useCase.comingSoon}
              />
            ))}
          </UseCasesContainer>
        </StyledSection>
      </SubContainer>
    </Container>
  );
};

export default ProductSection;
