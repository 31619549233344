// @flow
import React, { lazy, Suspense } from 'react';
import DelayRendering from 'components/DelayRendering';
import { Text } from '@quid/react-core';
import Navbar from 'components/Navbar';
import type { Props } from './NewSearch';
import Scaffhold, { Container, Main } from 'components/Scaffhold';

const NewSearch = lazy(() => import('./NewSearch'));

const Fallback = () => (
  <DelayRendering>
    <Scaffhold>
      <Navbar
        type="PRO"
        displayName={<Text.Skeleton>fullname</Text.Skeleton>}
      />
      <Container>
        <Main />
      </Container>
    </Scaffhold>
  </DelayRendering>
);

export default (props: Props) => (
  <Suspense fallback={<Fallback />}>
    <NewSearch {...props} />
  </Suspense>
);
