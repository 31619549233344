//@flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { redirectToLogin } from '@quid-private/integrations/lib/helpers/window';
import { HorizontalSpinner } from '@quid-private/react-components';
import { Text } from '@quid/react-core';
import useOnlineStatus from '@rehooks/online-status';

type Props = {
  label?: string,
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const OfflineMessage = styled(Text)`
  margin-bottom: 1em;
`;
OfflineMessage.defaultProps = {
  as: 'h1',
  type: 'xlarge bold',
};

const RedirectToLogin = ({ label = 'Redirecting to login page.' }: Props) => {
  const onlineStatus = useOnlineStatus();
  // If the app is not connected to internet, show a page where we ask to
  // go back online to proceed, otherwise we'd bring the user to a page they
  // can't load because offline
  if (onlineStatus === false) {
    return (
      <Container>
        <OfflineMessage>
          You appear to be offline, please connect to internet to proceed.
        </OfflineMessage>
        <Text as="a" type="large link bold" href={window.location.href}>
          Click here to reload
        </Text>
      </Container>
    );
  }

  redirectToLogin();
  return (
    <Container>
      <HorizontalSpinner label={label} />
    </Container>
  );
};

export default RedirectToLogin;
