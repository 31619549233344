// @flow
import React from 'react';
import { HorizontalSpinner } from '@quid-private/react-components';
import { ThemeProvider } from '@quid/theme';
import Modal from 'components/Modal';
import { ClassNames } from '@emotion/core';

export type Props = { message: string };

export default function SpinnerModal({ message, ...props }: Props) {
  return (
    <ThemeProvider theme="light">
      <ClassNames>
        {({ css }) => (
          <Modal
            {...props}
            innerClassName={css`
              position: relative;
              top: -20px;
              display: flex;
              justify-content: center;
            `}
            centerVertically
          >
            <HorizontalSpinner label={message} />
          </Modal>
        )}
      </ClassNames>
    </ThemeProvider>
  );
}
