// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Text, Button, Icon } from '@quid/react-core';
import UserAvatar from '../UserAvatar';

import type { User } from 'types/User';

const SHARED_AMOUNT = 7;

export const Footer = styled('div')`
  padding: 1.39em;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid var(--gray1);
  min-height: 7.3em;
`;

const Users = styled('div')`
  margin-top: 0.71em;
  display: flex;
`;

const SharedMore = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  height: 2.14em;
  width: 2.14em;
  border-radius: 2px;
  background-color: var(--skullWhite);
  border: 1px solid var(--gray1);
  margin-right: 0.48em;
`;

type OwnedFooterProps = {
  sharedWith: Array<?User>,
  onOpenShareManager: () => void,
};

const getSharedUsersList = (sharedWith, showMore): ?Array<React$Node> => {
  const users = sharedWith.slice(
    0,
    showMore ? SHARED_AMOUNT : SHARED_AMOUNT + 1
  );

  if (!users.length) {
    return null;
  }

  return users.map(
    (user, idx) =>
      !!user && (
        <UserAvatar fullName={user.fullName} key={idx} data-pii="true" />
      )
  );
};

export const OwnedFooter = ({
  sharedWith,
  onOpenShareManager,
}: OwnedFooterProps) => {
  const sharedCount = sharedWith.length - 1;
  const showMore = sharedCount >= SHARED_AMOUNT;

  return (
    <Footer>
      <Text as="p" type="secondary">
        Shared with:
      </Text>
      <Users data-context="shared-with-users">
        {getSharedUsersList(sharedWith, showMore)}
        {showMore && (
          <SharedMore type="bold">{`+ ${sharedCount -
            SHARED_AMOUNT}`}</SharedMore>
        )}
        <Button data-action="share" onClick={onOpenShareManager}>
          <Icon name="share" />
        </Button>
      </Users>
    </Footer>
  );
};

export const SharedFooter = ({ sharedBy }: { sharedBy: string }) => (
  <Footer>
    <Text as="p" type="secondary">
      Shared by:
    </Text>
    <Users data-context="shared-by">
      <UserAvatar fullName={sharedBy} data-pii="true" />
    </Users>
  </Footer>
);

export const BaseFooter = ({ text }: { text?: string })=> (
  <Footer>
    <Text as="p" type="secondary" style={{ marginTop: '30px' }}>
      {text}
    </Text>
  </Footer>
);
