// @flow
// This code will handle the case where all our error handlers
// failed, and the user is facing a blank screen, with possibly
// a stale service worker that won't update and lock the user
// into a bad state forever
// If that happens, we forcefully unregister the service worker
// and ask the user to reload the page, so that the application
// can download from scratch and start from a clean state
export const apocalypseHandler = () => {
  window.addEventListener('error', async err => {
    // always log the error to help debugging
    // eslint-disable-next-line no-console
    console.error(err);

    // If no service worker is available, our work ends here
    // because it's not dangerous to break the application
    if (!navigator.serviceWorker) {
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    if (registration.installing || registration.waiting) {
      // on development builds of React, error boundaries don't stop
      // errors from bubbling up to the window error handler, but
      // considering we don't have Service Workers on development envs
      // we can safely skip this redirect
      // https://github.com/facebook/react/issues/12897#issuecomment-410036991
      if (process.env.NODE_ENV !== 'development') {
        window.location.href = '/error.html';
      }
    }
  });
};
