// @flow
import React from 'react';
import { Modal } from '@quid/react-layouts';
import { Label, Button, InputText } from '@quid/react-forms';
import styled from '@emotion/styled/macro';

const WideInputText = styled(InputText)`
  width: 100%;
`;

type Props = {
  isOpen: boolean,
  // flowlint-next-line unclear-type:warn
  onSubmit: Function,
  value: string,
  // flowlint-next-line unclear-type:warn
  onChange: Function,
  submitting: boolean,
};

const NewProjectModal = ({
  isOpen,
  onSubmit,
  value,
  onChange,
  submitting,
  ...rest
}: Props) => (
  <Modal
    title="Create New Project"
    data-context="create-project-modal"
    centerVertically
    isOpen={isOpen}
    renderActionRight={actionClassName => [
      <Button
        key="cancel"
        to="/apps/projects"
        disabled={submitting}
        data-action="cancel"
        className={actionClassName}
      >
        Cancel
      </Button>,
      <Button
        key="save"
        importance="primary"
        type="submit"
        disabled={submitting}
        data-action="save"
        className={actionClassName}
      >
        Create
      </Button>,
    ]}
    isForm
    formProps={{
      onSubmit: evt => {
        evt.preventDefault();
        onSubmit();
      },
    }}
    {...rest}
  >
    <Label>Give your new project a name:</Label>
    <WideInputText
      value={value}
      onChange={onChange}
      data-context="name-input"
      placeholder="Enter project name"
      pattern=".*\S.*"
      title="Please provide a non empty project name."
      maxLength="255"
      autoFocus={true}
      required
    />
  </Modal>
);

export default NewProjectModal;
