// @flow
import React, { type Node } from 'react';
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';
import Ellipsis from '@quid/react-ellipsis';

// 26 is the height of a line of text, 3 the max allow lines
// unfortunately `Ellipsis` supports only pixel values, no ems
const maxTitleHeight = 26 * 3;

type Props = {
  children: Node,
};

const ProjectTitle = styled(({ children, ...props }: Props) => (
  <Text as="h1" type="xlarge bold" {...props}>
    {typeof children === 'string' ? (
      <Ellipsis maxHeight={maxTitleHeight}>{children}</Ellipsis>
    ) : (
      children
    )}
  </Text>
))`
  word-break: break-word;
  max-height: ${maxTitleHeight}px;
  overflow: hidden;
`;

export default ProjectTitle;
