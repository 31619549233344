// @flow
import React, { Fragment } from 'react';
import { Modal } from '@quid/react-layouts';
import { Text } from '@quid/react-core';
import {
  Button,
  InputText,
  InputGroup as QuidInputGroup,
} from '@quid/react-forms';
import UserRow from './UserRow';
import pluralize from 'pluralize';
import styled from '@emotion/styled/macro';

const InputGroup = styled(QuidInputGroup)`
  width: 100%;
  margin-bottom: 2.14em;
`;

const TextWithMargin = styled(Text)`
  margin-bottom: 0.71em;
`;

type Props = {
  isOpen: boolean,
  // flowlint-next-line unclear-type:warn
  onClose: Function,
  // flowlint-next-line unclear-type:warn
  project: any,
  // flowlint-next-line unclear-type:warn
  onSubmit: Function,
  // flowlint-next-line unclear-type:warn
  onUnshare: Function,
};

const ShareProjectModal = ({
  isOpen,
  onClose,
  project,
  onSubmit,
  onUnshare,
  ...rest
}: Props) => (
  <Modal
    isOpen={isOpen}
    data-context="project-share-manager-modal"
    title="Share Project"
    renderActionRight={actionClassName => (
      <Button
        key="close"
        onClick={onClose}
        data-action="close"
        className={actionClassName}
      >
        Close
      </Button>
    )}
    {...rest}
  >
    {project && (
      <Fragment>
        <form onSubmit={onSubmit}>
          <InputGroup>
            <InputText
              name="email"
              type="email"
              required
              placeholder="Enter an email address"
              data-pii="true"
            />
            <Button type="submit">Invite user</Button>
          </InputGroup>
        </form>
        <TextWithMargin as="p">
          {project.sharedWith.length === 0
            ? 'This project has not been shared yet.'
            : `Shared with ${pluralize(
                'user',
                project.sharedWith.length,
                true
              )}:`}
        </TextWithMargin>
        {project.sharedWith.map((user, key) => (
          <UserRow key={key} {...user} onUnshare={onUnshare(user, project)} />
        ))}
      </Fragment>
    )}
  </Modal>
);

export default ShareProjectModal;
