// @flow
import React from 'react';
import { Text } from '@quid/react-core';
import UserAvatar from '../UserAvatar';
import type { User } from 'types/User';
import styled from '@emotion/styled/macro';

const List = styled.ul`
  margin-top: 0.71em;
  margin-bottom: 0.71em;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-top: 0.35em;
  margin-bottom: 0.35em;
`;

const Avatar = styled(UserAvatar)`
  margin-right: 0.21em;
`;

type Props = {
  users: Array<User>,
};

export function UsersList({ users, ...props }: Props) {
  return (
    <List {...props}>
      {users.map((user, key) => (
        <Item key={key}>
          <Avatar fullName={user.fullName} data-pii="true" />
          <Text data-pii="true">{user.fullName}</Text>
        </Item>
      ))}
    </List>
  );
}

export default UsersList;
