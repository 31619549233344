// @flow
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

const Content = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 1.39em;
  padding-bottom: 0.74em;
  height: 10.71em;
`;

export default Content;
