// @flow
import React, { lazy, Suspense } from 'react';
import DelayRendering from 'components/DelayRendering';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';

const Analyses = lazy(() => import('./Analyses'));

const Fallback = () => (
  <DelayRendering>
    <Scaffhold>
      <Navbar type="APPS" />
      <Container>
        <Main />
      </Container>
    </Scaffhold>
  </DelayRendering>
);

export default () => (
  <Suspense fallback={<Fallback />}>
    <Analyses />
  </Suspense>
);
