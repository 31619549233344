// @flow
import gql from 'graphql-tag';

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($name: String!) {
    createProject(name: $name) {
      id
    }
  }
`;

export const PROJECTS_QUERY = gql`
  query AllProjects($type: ProjectType, $cursor: Int, $limit: Int) {
    projects(type: $type, cursor: $cursor, limit: $limit) {
      totalCount
      items {
        id
        name
        type
        timestamps {
          updatedAt
        }
        sharedWith {
          id
          firstName
          lastName
          fullName
          email
        }
        owner {
          id
          fullName
        }
        ... on AppsProject {
          public
          analyses {
            totalCount
          }
        }
        ... on ProProject {
          portfolios {
            totalCount
          }
        }
      }
    }
    me {
      id
      roles {
        name
      }
      fullName
    }
  }
`;

export const SHARE_PROJECT_MUTATION = gql`
  mutation shareProject($id: ID!, $email: String!) {
    shareProject(id: $id, email: $email) {
      id
    }
  }
`;

export const UNSHARE_PROJECT_MUTATION = gql`
  mutation unshareProject($id: ID!, $email: String!) {
    unshareProject(id: $id, email: $email) {
      id
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;
