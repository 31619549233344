//@flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Text, Icon } from '@quid/react-core';
import { SubMenu, SubMenuItem, SubMenuButton } from '../OptionsMenu';
import { getQuidProUrl } from 'modules/util';
import { Link } from 'react-router-dom';
import useAccessControl from 'utils/useAccessControl';
import useRouter from 'use-react-router';
import { useState } from 'react';
const CaretDown = styled(Icon)`
  margin-left: 8px;
`;

type Props = {
  path: string,
};

const AppSelector = styled(({ path, ...props }: Props) => {
  const { hasProAccess } = useAccessControl();
  const { match } = useRouter();
  const [isMenuOpen, setMenuStatus] = useState(false);
  const isProjectsPage = match.path === '/apps/projects';
  const showSelector = hasProAccess && isProjectsPage;

  return (
    <div {...props} tabIndex="0">
      <Link
        to={path}
        id="tooltip-project-list"
        onClick={event => {
          if (isProjectsPage) {
            event.preventDefault();
            setMenuStatus(!isMenuOpen);
          }
        }}
      >
        <Text type="bold">
          Apps {showSelector && <CaretDown name="caret_down" />}
        </Text>
      </Link>
      {showSelector && isMenuOpen && (
        <SubMenu>
          <SubMenuItem>
            <SubMenuButton href={getQuidProUrl()}>Pro</SubMenuButton>
          </SubMenuItem>
        </SubMenu>
      )}
    </div>
  );
})`
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;

  &:focus,
  &:focus-within,
  &:hover {
    ${SubMenu} {
      left: -10px;
      display: flex;
    }
  }
`;

export default AppSelector;
