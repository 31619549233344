// @flow
import React from 'react';
import { Text } from '@quid/react-core';
import styled from '@emotion/styled/macro';

const Container = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  height: 2.14em;
  width: 2.14em;
  border-radius: 2px;
  margin-right: 0.48em;
  color: var(--skullWhite);
  font-weight: bold;
`;

type Props = {
  fullName: string,
};

export function getInitials(name: string) {
  const initials = name.split(' ').map(str => str[0]);
  // eslint-disable-next-line no-useless-computed-key
  const { [0]: first, [initials.length - 1]: last } = initials;
  return `${first}${last}`.toUpperCase();
}

const UserAvatar = ({ fullName, ...props }: Props) => (
  <Container as="div" {...props}>
    {getInitials(fullName)}
  </Container>
);

export default UserAvatar;
