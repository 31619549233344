// @flow
import type { Project } from 'types/Project';
import type { User } from 'types/User';
export const PUBLIC_ADMIN_ROLE = 'apps_demo_project_admin';

export const _isAdmin = (me: User) =>
  me.roles.map(r => r.name).includes(PUBLIC_ADMIN_ROLE);

export const canCreate = ({ project, me }: { project: Project, me: User }) => {
  if (project.public) {
    return _isAdmin(me);
  }

  // Owners can create analyses on the project
  // $FlowIgnore: project has owner at this point
  if (me.id === project.owner.id) {
    return true;
  }

  // Shared users can create analyses the project
  if (project.sharedWith.map(u => u.id).includes(me.id)) {
    return true;
  }

  return false;
};

export const canDelete = ({ project, me }: { project: Project, me: User }) => {
  if (project.public) {
    return _isAdmin(me);
  }

  // Owners can delete the project
  // $FlowIgnore: project has owner at this point
  if (me.id === project.owner.id) {
    return true;
  }

  return false;
};

export const canSchedule = ({
  project,
  me,
}: {
  project: Project,
  me: User,
}) => {
  if (project.public) {
    return false;
  }

  // $FlowIgnore: project has owner at this point
  if (me.id === project.owner.id) {
    return true;
  }

  return false;
};
