// @flow
import React, { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { Container } from '@quid/react-tooltip';
import { ThemeProvider } from '@quid/theme';

const TooltipContainer = styled(
  forwardRef((props, ref) => (
    <ThemeProvider theme="light">
      <Container {...props} ref={ref} />
    </ThemeProvider>
  ))
)`
  cursor: auto;
  font-weight: normal;
`;

export default TooltipContainer;
