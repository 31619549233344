// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';
import Content from './Content';
import Container from './Container';
import { Footer } from './Footer';

const SkeletonContainer = styled(Container)`
  border-color: ${props => props.theme.colors.gray1};
  background-color: ${props => props.theme.colors.gray1};

  box-shadow: none;
  &: hover {
    box-shadow: none;
  }
`;

const SkeletonFooter = styled(Footer)`
  padding: 1em 1.39em;
`;

const SkeletonContent = Content.withComponent('div');

const SkeletonDetails = styled.div`
  margin-top: auto;
`;

const Skeleton = () => (
  <SkeletonContainer>
    <SkeletonContent>
      <Text.Skeleton as="h1" type="bold xlarge">
        -------------
      </Text.Skeleton>
      <SkeletonDetails>
        {' '}
        <Text.Skeleton type="bold xlarge">--------</Text.Skeleton>
      </SkeletonDetails>
    </SkeletonContent>
    <SkeletonFooter>
      <Text.Skeleton as="p">------ --- ---</Text.Skeleton>
      <br />
      <Text.Skeleton>---</Text.Skeleton>
    </SkeletonFooter>
  </SkeletonContainer>
);

export default Skeleton;
