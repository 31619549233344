// @flow
import React, { useCallback, Fragment, forwardRef } from 'react';
import { BasicPanel as Panel } from '@quid-private/react-components';
import styled from '@emotion/styled/macro';
import { Text, Button, Icon } from '@quid/react-core';
import { InputToggle, Label } from '@quid/react-forms';
import { Tooltip } from '@quid/react-tooltip';
import Spacer from '../Spacer';
import { toDateString } from 'utils/date';
import useRouter from 'use-react-router';
import { deleteAnalysisUrl } from 'utils/url';
import type { AnalysisTypes } from 'types/AnalysisTypes';
import type { Schedule } from 'types/Analysis';
// $FlowFixMe: https://github.com/facebook/flow/issues/5749
import { apps } from 'config/apps.json';
import SchedulerPanel from './SchedulerPanel';
import ErrorTooltip from 'components/ErrorTooltip';
import Tag from './Tag';

const SEPARATOR = props => props.theme.colors.gray1;

const black = alpha => `rgba(18, 18, 18, ${alpha})`;

const Perimeter = styled(Panel)`
  box-shadow: 0 2px 2px 0 ${black(0.2)};
  margin-bottom: ${props => props.theme.sizes.regular};
  border: 1px solid ${SEPARATOR};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 3px 6px 0 ${black(0.2)}, 0 3px 6px 0 ${black(0.16)};
  }
`;

const PerimeterSkeleton = styled(Perimeter)`
  box-shadow: none;
  border-color: var(--white);
`;

const Content = styled.div`
  padding: ${props =>
    `0 ${props.theme.sizes.large} ${props.theme.sizes.regular} ${props.theme.sizes.large}`};
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Text)`
  margin-top: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

const Action = styled.div`
  display: flex;
  align-items: ${props => (props.expanded ? 'flex-start' : 'center')};
  flex-direction: ${props => (props.expanded ? 'column' : 'row')};
  border-top: 1px solid ${SEPARATOR};
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 2px 2px;
`;

const ExpandedSection = styled.div`
  display: flex;
  border-bottom: ${props => (props.hideBorder ? `0` : `1px `)} solid
    ${SEPARATOR};

  padding: ${({ theme: { sizes } }) =>
    `0 ${sizes.large} ${sizes.regular} ${sizes.large}`};
  align-self: stretch;
`;

const ActionLeft = styled.div`
  display: flex;
  margin: ${({ theme: { sizes } }) =>
    `${sizes.regular} 0 ${sizes.regular} ${sizes.large}`};
`;

const ActionRight = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-shrink: 0;
  align-items: center;
  margin: ${({ theme: { sizes } }) => `${sizes.regular} ${sizes.large}`};
`;

const ActionSkeleton = styled(Action)`
  border-top-color: transparent;
`;

const TextRight = styled(Text)`
  display: flex;
  white-space: nowrap;
  margin-left: auto;
  padding-top: 1em;
  padding-right: 1em;
  text-align: right;
`;

const HeadlineBorder = styled.div`
  display: flex;
  text-transform: uppercase;
  color: black;
  padding-top: 1em;
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 ${props => props.theme.sizes.regular};
  &::before {
    content: '•';
  }
`;

const BulletSeparatedList = styled(({ list, ...props }) => (
  <Text {...props}>
    {!!list &&
      list.map((topic, index) => (
        <Fragment key={index}>
          {index !== 0 && <Separator />}
          {topic}
        </Fragment>
      ))}
  </Text>
))`
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin-top: 1em;
`;
BulletSeparatedList.defaultProps = {
  as: 'p',
  type: 'secondary',
};

const TextFromDate = styled(Text)`
  margin-top: 1.5em;
`;

type Props = {
  type: AnalysisTypes,
  id: string,
  title: string,
  createdAt: Date,
  topics?: Array<string>,
  competitors?: Array<string>,
  timeRange: [Date, Date],
  viewLink?: string,
  canSchedule: boolean,
  canAccessSchedule: boolean,
  canDelete: boolean,
  canView?: boolean,
  list: Array<{ to: ?string, label: string, error: ?string }>,
  schedule: Schedule,
  updateSchedule?: ({
    variables: { analysisId: string, payload: $Shape<Schedule> },
  }) => void,
};

// flowlint-next-line unclear-type:warn
const DeleteButton = ({ onClick, ...props }: { onClick: Function }) => (
  <Button {...props} onClick={onClick}>
    <Icon name="trash" />
  </Button>
);

const ErrorIcon = styled(
  forwardRef(({ open, close, ...props }, ref) => (
    <Icon
      tabIndex={0}
      onMouseEnter={open}
      onFocus={open}
      onBlur={close}
      ref={ref}
      name="warning"
      {...props}
    />
  ))
)`
  color: ${props => props.theme.colors.red};
  font-size: 22px;
  margin-top: 4px;
  &:focus {
    outline: 0;
  }
  &:focus-visible {
    text-shadow: 0 0 2px ${props => props.theme.colors.red};
  }
`;

// FIME: replace this strategy with a complete refactor of the footer to suit our current needs
const PushRight = styled.div`
  margin-right: auto;
`;

export function AnalysisCard({
  type,
  id,
  title,
  createdAt,
  topics,
  competitors,
  timeRange,
  viewLink,
  canSchedule,
  canAccessSchedule,
  canDelete,
  canView = true,
  list,
  schedule,
  updateSchedule,
  ...props
}: Props) {
  const { match, history } = useRouter();
  const setSchedule = useCallback(
    schedule =>
      updateSchedule &&
      updateSchedule({
        variables: { analysisId: id, payload: schedule },
      }),
    [updateSchedule, id]
  );

  const appConfig = apps.find(app => app.type === type) || {};

  const appSupportsSchedule = Boolean(appConfig.scheduler);
  const mainRun = list[0];

  return (
    <Perimeter>
      <ContentTitle>
        <HeadlineBorder type="bold">
          <Tag analysisType={type} />
        </HeadlineBorder>
        <TextRight as="p" type="secondary" data-context="created">
          Created on {toDateString(createdAt, { month: 'long' })}
        </TextRight>
      </ContentTitle>
      <Content>
        <TextFromDate as="p" type="secondary" data-context="date-interval">
          {`From ${toDateString(timeRange[0], {
            month: 'long',
          })} to ${toDateString(timeRange[1], { month: 'long' })}`}
        </TextFromDate>
        <Title as="h1" type="xlarge bold" data-context="title">
          {title}
        </Title>
        <BulletSeparatedList data-context="topics" list={topics} />
        <BulletSeparatedList data-context="competitors" list={competitors} />
      </Content>
      <Action
        expanded={Boolean(
          canAccessSchedule && appSupportsSchedule && schedule.enabled
        )}
      >
        {canAccessSchedule && appSupportsSchedule && (
          <>
            <ActionLeft>
              <Label
                labelAlignment="left"
                renderControl={controlClass => (
                  <InputToggle
                    checked={schedule && schedule.enabled}
                    data-action="toggle-scheduler"
                    disabled={!canSchedule}
                    onChange={evt =>
                      updateSchedule &&
                      updateSchedule({
                        variables: {
                          analysisId: id,
                          payload: { enabled: evt.target.checked },
                        },
                      })
                    }
                    className={controlClass}
                  />
                )}
              >
                <Text>Scheduler</Text>
              </Label>
            </ActionLeft>
            {schedule && schedule.enabled && (
              <ExpandedSection
                hideBorder={
                  (!mainRun || !mainRun.error) && !canDelete && !canView
                }
              >
                <SchedulerPanel
                  list={list}
                  scheduleFrequencies={appConfig.scheduler.frequencies}
                  data-context="scheduler-panel"
                  disabled={!canSchedule}
                  schedule={schedule}
                  setSchedule={setSchedule}
                />
              </ExpandedSection>
            )}
          </>
        )}

        <ActionRight>
          {mainRun && mainRun.error && (
            <Tooltip
              renderTooltip={props => (
                <ErrorTooltip {...props}>{mainRun.error}</ErrorTooltip>
              )}
            >
              {({ ref, open, close }) => (
                <ErrorIcon ref={ref} open={open} close={close} />
              )}
            </Tooltip>
          )}
          <PushRight />
          {canDelete && (
            <DeleteButton
              data-action="delete-analysis"
              onClick={() =>
                history.push(
                  deleteAnalysisUrl({
                    projectId: String(match.params.projectId),
                    analysisId: id,
                  })
                )
              }
            />
          )}
          <Spacer />

          {canView && (
            <Button
              to={viewLink}
              importance="primary"
              disabled={!viewLink}
              data-action="view-analysis"
              data-analysis-id={id}
              data-analysis-type={type}
            >
              {list.length > 1 ? 'View latest' : 'View'}
            </Button>
          )}
        </ActionRight>
      </Action>
    </Perimeter>
  );
}

AnalysisCard.Skeleton = () => (
  <PerimeterSkeleton>
    <ContentTitle>
      <HeadlineBorder>
        <Tag isLoading />
      </HeadlineBorder>
      <TextRight>
        <Text.Skeleton type="secondary" data-context="created">
          ------
        </Text.Skeleton>
      </TextRight>
    </ContentTitle>
    <Content>
      <Text.Skeleton as="p" type="secondary" data-context="date-interval">
        --------------------------------
      </Text.Skeleton>
      <Title>
        <Text.Skeleton type="xlarge" data-context="title">
          ------------------
        </Text.Skeleton>
      </Title>
      <Text.Skeleton as="p" type="secondary" data-context="topics">
        --------------------------------
      </Text.Skeleton>
      <Text.Skeleton as="p" type="secondary" data-context="competitors">
        --------------------------------
      </Text.Skeleton>
    </Content>
    <ActionSkeleton>
      <ActionRight>
        <Text.Skeleton as="p">View XX</Text.Skeleton>
      </ActionRight>
    </ActionSkeleton>
  </PerimeterSkeleton>
);

export default AnalysisCard;
