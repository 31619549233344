// @flow
import styled from '@emotion/styled/macro';
import BREAKPOINT from 'utils/breakpoint';

const border = '1px solid var(--border)';

export const Container = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    flex-direction: column;
  }
`;

export const Side = styled.aside`
  width: 310px;
  border-right: ${border};
  padding: 1.39em 0.46em;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    flex-direction: column;
    border-right: none;
    border-bottom: ${border};
    width: auto;
    display: flex;
    flex-direction: row;
    padding: ${props => props.theme.sizes.large};
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Scaffhold = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  isolation: isolate;
  background-color: ${props =>
    props.background === 'dark' ? 'var(--black)' : '#FFFFFF'};
  --border: ${props =>
    props.background === 'dark' ? 'transparent' : 'var(--gray1)'};
`;
export default Scaffhold;
