// @flow
import React, { Component, type Node, Fragment } from 'react';
import { ClassNames } from '@emotion/core';
import { Text } from '@quid/react-core';
import styled from '@emotion/styled/macro';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';
import { Bugsnag } from '@quid-private/integrations';

const CenteredMain = styled(Main)`
  align-items: center;
  justify-content: center;
`;

type Props = {
  children: Node,
  theme?: 'dark' | 'light',
  errorMessage: Node,
  showDetails?: boolean,
  showError: boolean,
};
type State = {
  hasError: boolean,
  message: string,
  stack: string,
};

export default class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    theme: 'light',
    showDetails: false,
    showError: false,
    children: null,
  };

  state = { hasError: false, message: '', stack: '' };

  componentDidCatch(e: Error) {
    if (Bugsnag._bugsnagClient) {
      Bugsnag._bugsnagClient.notify(e);
    }

    this.setState({ hasError: true, message: e.message, stack: e.stack });
  }

  render() {
    if (this.props.showError || this.state.hasError) {
      const { theme, errorMessage, showDetails } = this.props;

      return (
        <Scaffhold background={theme} data-theme={theme}>
          <Navbar type="APPS" />
          <Container>
            <CenteredMain>
              <Text as="h1" type="xlarge">
                {errorMessage}
              </Text>
              {Boolean(showDetails) && (
                <ClassNames>
                  {({ css, theme }) => (
                    <Fragment>
                      <Text
                        as="p"
                        className={css`
                          margin: ${theme.sizes.regular};
                        `}
                        type="disabled"
                      >
                        <span role="img" aria-label="warning">
                          ⚠️
                        </span>{' '}
                        {this.state.message}
                      </Text>
                      <Text
                        as="p"
                        className={css`
                          margin: ${theme.sizes.regular};
                        `}
                        type="disabled"
                      >
                        {this.state.stack}
                      </Text>
                    </Fragment>
                  )}
                </ClassNames>
              )}
            </CenteredMain>
          </Container>
        </Scaffhold>
      );
    }
    return this.props.children;
  }
}
