// @flow
import React, { createContext, type Node } from 'react';
import * as serviceWorker from '../serviceWorker';

type ContextType = {
  assetsUpdateReady: boolean,
  assetsCached: boolean,
  updateAssets: () => void,
};

const initialContextValue = {
  assetsUpdateReady: false,
  assetsCached: false,
  // fallback in case something goes wrong
  updateAssets: () => void window.location.reload(),
};
const ServiceWorkerContext = createContext<ContextType>(initialContextValue);

function ServiceWorkerProvider({ children }: { children: Node }) {
  const [waitingServiceWorker, setWaitingServiceWorker] = React.useState(null);
  const [assetsUpdateReady, setAssetsUpdateReady] = React.useState(false);
  const [assetsCached, setAssetsCached] = React.useState(false);
  const [requestAutoReload, setRequestAutoReload] = React.useState(true);

  const value = React.useMemo(
    () => ({
      assetsUpdateReady,
      assetsCached,
      // Call when the user confirm update of application and reload page
      updateAssets: () => {
        setRequestAutoReload(false);
        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener('statechange', event => {
            if (event.target.state === 'activated') {
              window.location.reload();
            }
          });

          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        } else {
          window.location.reload();
        }
      },
    }),
    [assetsUpdateReady, assetsCached, waitingServiceWorker]
  );

  // Once on component mounted subscribe to Update and Succes events in
  // CRA's service worker wrapper
  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting);
        setAssetsUpdateReady(true);
      },
      // We can get into this state when the service worker is waiting
      // for all the tabs to be closed to proceed with the installation
      // We want to display the "Update" button to allow the user to
      // easily force the SW update
      onWaiting: registration => {
        setWaitingServiceWorker(registration);
        setAssetsUpdateReady(true);
      },
      // This gets called after the service worker gets updated
      onSuccess: () => {
        setAssetsCached(true);
      },
      onActivated: () => {
        if (requestAutoReload) {
          window.location.reload();
        }
      },
    });
  }, [requestAutoReload]);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}

function useServiceWorker() {
  const context = React.useContext(ServiceWorkerContext);

  if (!context) {
    throw new Error(
      'useServiceWorker must be used within a ServiceWorkerProvider'
    );
  }

  return context;
}

export { ServiceWorkerProvider, useServiceWorker };
