//@flow
import styled from '@emotion/styled/macro';

const ScreenContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default ScreenContainer;
