//@flow
import React from 'react';
import styled from '@emotion/styled/macro';
import Color from 'color';
import { Icon } from '@quid/react-core';
import { Tooltip } from '@quid/react-tooltip';
import { useQuery } from '@apollo/react-hooks';
import TooltipContainer from 'components/TooltipContainer';
import useRouter from 'use-react-router';
import css from '@emotion/css/macro';
import Ellipsis from 'components/Ellipsis';
import InputDetails from 'components/InputDetails';
import TextSkeleton from 'components/TextSkeleton';
import { useIntegrationContext } from 'components/IntegrationContext';
import type { User } from 'types/User';
import { getMe as GET_ME } from 'modules/queries';

const MENU_WIDTH = '180px';

const MenuIcon = styled(Icon)`
  font-size: 16px;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const FullName = styled(Ellipsis)`
  margin-right: 10px;
  transition: color 0.2s ease-in-out;
`;

const MenuItem = styled.li`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  ${props =>
    props.isName
      ? css`
          padding: 0 10px;
          width: ${MENU_WIDTH};
          justify-content: space-between;
        `
      : css`
          width: 50px;
        `}
  ${props =>
    props.isSubmenu &&
    css`
      position: relative;
      border-left: 1px solid ${props.theme.colors.gray6};
      &:hover {
        background-color: ${props.theme.colors.gray6};
        ${SubMenu} {
          display: flex;
        }
      }
    `}
`;

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  margin-right: -5px;
`;

export const SubMenu = styled.ul`
  display: none;
  flex-direction: column;
  width: ${MENU_WIDTH};
  position: absolute;
  top: 50px;
  left: -1px;
  z-index: 1;
  background-color: ${props => props.theme.colors.gray6};
  box-shadow: 0px 3px 4px
    ${Color(props => props.theme.colors.black)
      .alpha(0.3)
      .string()};
`;

export const SubMenuItem = styled.li`
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid ${props.theme.colors.gray5};
    `}
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.gray5};
  }
`;

export const SubMenuButton = styled.a`
  display: block;
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TooltipContent = styled(TooltipContainer)`
  max-width: 370px;
  padding: 10px;
`;

const OptionsMenu = () => {
  const {
    match: {
      params: { analysisId },
    },
  } = useRouter();
  const { data: { me } = {} } = useQuery<{ me: User }>(GET_ME);
  const { LaunchDarkly } = useIntegrationContext();
  const resourceCenterURL = LaunchDarkly.flag('help-center-url-quid', '');

  return (
    <MenuList>
      {analysisId && (
        <MenuItem>
          <Tooltip
            id="project_details_tooltip"
            closeDelay={200}
            renderTooltip={props => (
              <TooltipContent {...props}>
                <InputDetails analysisId={analysisId} />
              </TooltipContent>
            )}
          >
            {({ ref, open }) => (
              <MenuIcon ref={ref} name="info_circle" onMouseEnter={open} />
            )}
          </Tooltip>
        </MenuItem>
      )}

      <MenuItem isSubmenu isName>
        <FullName>{me ? me.fullName : <TextSkeleton width="80px" />}</FullName>
        <MenuIcon name="gear" />
        <SubMenu>
          <SubMenuItem>
            <SubMenuButton href={resourceCenterURL} target="_blank">
              Resource Center
            </SubMenuButton>
          </SubMenuItem>
          <SubMenuItem>
            <SubMenuButton
              href="https://quid.com/privacy-policy"
              target="_blank"
            >
              Privacy policy
            </SubMenuButton>
          </SubMenuItem>
          <SubMenuItem>
            <SubMenuButton
              href="https://quid.com/terms-and-conditions"
              target="_blank"
            >
              Terms & Conditions
            </SubMenuButton>
          </SubMenuItem>
          <SubMenuItem borderTop>
            <SubMenuButton href="/auth/logout">Log Out</SubMenuButton>
          </SubMenuItem>
        </SubMenu>
      </MenuItem>
    </MenuList>
  );
};

export default OptionsMenu;
