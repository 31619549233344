// @flow
import React from 'react';
import { Text } from '@quid/react-core';
import styled from '@emotion/styled/macro';
import BREAKPOINT from 'utils/breakpoint';

const Container = styled.li`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px;
  min-height: 130px;
  @media (max-width: ${BREAKPOINT.MOBILE}px) {
    width: 100%;
    min-height: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const TitleText = styled(Text)`
  &::before {
    content: '•';
    margin-right: 3px;
  }
`;

TitleText.defaultProps = { type: 'normal bold', as: 'h3' };

const ComingSoonText = styled(Text)`
  font-style: italic;
  font-size: 12px;
  line-height: 1.83;
  color: ${props => props.theme.colors.aqua};
  margin-left: 5px;
`;

ComingSoonText.defaultProps = { type: 'large italic' };

const SummaryText = styled(Text)`
  color: ${props => props.theme.colors.gray2};
`;

SummaryText.defaultProps = { type: 'normal regular' };

type UseCaseCardProps = {
  title: string,
  summary: string,
  comingSoon?: boolean,
};

const UseCaseCard = ({ title, summary, comingSoon }: UseCaseCardProps) => {
  return (
    <Container>
      <TitleContainer>
        <TitleText>{title}</TitleText>
        {comingSoon != null && comingSoon && (
          <ComingSoonText>Coming Soon!</ComingSoonText>
        )}
      </TitleContainer>
      <SummaryText>{summary}</SummaryText>
    </Container>
  );
};

export default UseCaseCard;
