// @flow
import * as React from 'react';
import styled from '@emotion/styled/macro';
import { Text, Button } from '@quid/react-core';
import Scaffhold, { Container, Main } from 'components/Scaffhold';
import Navbar from 'components/Navbar';
import backgroundImage from './background.svg';

const BackgroundMain = styled(Main)`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
`;

const Message = styled(Text)`
  font-size: 35px;
`;

const CallToAction = styled(Button)`
  margin-top: 30px;
`;

type Props = {
  children?: React.Node,
};

export const Error = (props: Props) => (
  <Scaffhold background="light" data-theme="light">
    <Navbar type="APPS" />
    <Container>
      <BackgroundMain>{props.children}</BackgroundMain>
    </Container>
  </Scaffhold>
);

const NotFound = () => (
  <Error>
    <Message>Whoops!</Message>
    <Message>Something went wrong.</Message>
    <CallToAction importance="primary" to="/">
      Return to home page
    </CallToAction>
  </Error>
);

const Forbidden = () => (
  <Error>
    <Message data-context="forbidden">Not authorized</Message>
    <Message>Please return to home page</Message>
    <CallToAction importance="primary" to="/">
      Return to home page
    </CallToAction>
  </Error>
);

export { NotFound, Forbidden };
