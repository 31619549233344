// @flow
import { useEffect } from 'react';
import useRouter from 'use-react-router';
import { useIntegrationContext } from 'components/IntegrationContext';
import { setContext } from 'utils/analytics';
import type { Insight } from 'types/Insight';
import type { Analysis } from 'types/Analysis';
import type { Bundle } from 'types/Bundle';
import type { InsightGroup } from 'types/InsightGroup';

type Arguments = {
  analysis?: ?Analysis,
  insight?: ?Insight,
  bundle?: ?Bundle,
  insightGroup?: ?InsightGroup,
};

const useAnalyticsContext = (args: ?Arguments) => {
  const { analysis, insight, bundle, insightGroup } = args || {};
  const { HeapAnalytics } = useIntegrationContext();
  const { match } = useRouter();

  useEffect(
    () =>
      void setContext(HeapAnalytics, {
        data: {
          source: 'Quid Apps',
          project_id:
            match && match.params && match.params.projectId
              ? match.params.projectId
              : '',
          analysis_id:
            match && match.params.analysisId ? match.params.analysisId : '',
          analysis_type: analysis && analysis.type ? analysis.type : '',
          output_id:
            match && match.params.outputId ? match.params.outputId : '',
          lens_id: bundle ? bundle.id : '',
          lens_name: bundle ? bundle.title : '',
          tab_id: insightGroup ? insightGroup.id : '',
          tab_name: insightGroup ? insightGroup.name : '',
          insight_id: insight ? insight.id : '',
          insight_type: insight ? insight.type : '',
          insight_title: insight ? insight.title : '',
        },
      }),
    [analysis, insight, bundle, insightGroup, match, HeapAnalytics]
  );
};

export default useAnalyticsContext;
