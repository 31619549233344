// @flow

export type HeapAnalyticsType = {
  // flowlint-next-line unclear-type:warn
  sendEvent: (string, Object) => void,
  // flowlint-next-line unclear-type:warn
  setContext: Object => void,
  clearContext: void => void,
};

export const sendEvent = (
  heapInstance: ?HeapAnalyticsType,
  // flowlint-next-line unclear-type:warn
  payload: { name: string, data: Object }
) => {
  // If Heap is not available (develop) we log the events in the console
  // for debugging purposes
  heapInstance
    ? heapInstance.sendEvent(payload.name, payload.data)
    : // eslint-disable-next-line no-console
      console.log('Heap event sent with following payload', payload);
};

export const setContext = (
  heapInstance: ?HeapAnalyticsType,
  // flowlint-next-line unclear-type:warn
  payload: { data: Object }
) => {
  if (heapInstance) {
    heapInstance.setContext(payload.data);
  }
};

export const clearContext = (heapInstance: ?HeapAnalyticsType) => {
  if (heapInstance) {
    heapInstance.clearContext();
  }
};
