// @flow
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import serialize from 'form-serialize';
import ShareProjectModal from 'components/ShareProjectModal';
import { useIntegrationContext } from 'components/IntegrationContext';
import {
  PROJECTS_QUERY,
  SHARE_PROJECT_MUTATION,
  UNSHARE_PROJECT_MUTATION,
} from 'screens/Projects/queries';

type Props = {
  isOpen: boolean,
  // flowlint-next-line unclear-type:warn
  onClose: Function,
  // flowlint-next-line unclear-type:warn
  project: any,
};

const ShareProject = ({ isOpen, onClose, project }: Props) => {
  const { LaunchDarkly } = useIntegrationContext();
  const [shareProject] = useMutation(SHARE_PROJECT_MUTATION, {
    refetchQueries: () => [
      {
        query: PROJECTS_QUERY,
        variables: {
          limit: 15,
          type: LaunchDarkly.flag('apps-pmt-projects-type', 'APPS'),
        },
      },
    ],
  });
  const [unshareProject] = useMutation(UNSHARE_PROJECT_MUTATION, {
    refetchQueries: () => [
      {
        query: PROJECTS_QUERY,
        variables: {
          limit: 15,
          type: LaunchDarkly.flag('apps-pmt-projects-type', 'APPS'),
        },
      },
    ],
  });

  return (
    <ShareProjectModal
      isOpen={isOpen}
      onClose={onClose}
      project={project}
      onSubmit={e => {
        e.preventDefault();
        const form = serialize(e.target, { hash: true });

        shareProject({
          variables: {
            email: form.email,
            id: project.id,
          },
        });
      }}
      onUnshare={(user, project) => () =>
        unshareProject({
          variables: {
            email: user.email,
            id: project.id,
          },
        })}
    />
  );
};

export default ShareProject;
