// @flow
import React from 'react';
import DeleteModal from 'components/DeleteModal';
import { Text } from '@quid/react-core';
import { useMutation } from '@apollo/react-hooks';
import {
  PROJECTS_QUERY,
  DELETE_PROJECT_MUTATION,
} from 'screens/Projects/queries';

type Props = {
  isOpen: boolean,
  // flowlint-next-line unclear-type:warn
  onClose: Function,
  // flowlint-next-line unclear-type:warn
  onDelete: Function,
  projectId: string,
  listType: string,
};

export const deleteProjectUpdater = (id: string, listType: string) => (
  // flowlint-next-line unclear-type:warn
  cache: Object
) => {
  const data = cache.readQuery({
    query: PROJECTS_QUERY,
    variables: { limit: 15, type: listType },
  });

  const updatedProjects = {
    ...data.projects,
    totalCount: data.projects.items.length - 1,
    items: data.projects.items.filter(a => a.id !== id),
  };

  const newData = {
    ...data,
    projects: updatedProjects,
  };

  cache.writeQuery({
    query: PROJECTS_QUERY,
    variables: { limit: 15, type: listType },
    data: newData,
  });
};

export const deleteProjectOptimistic = (projectId: string) => ({
  __typename: 'Mutation',
  deleteProject: {
    __typename: 'AppsProject',
    id: projectId,
  },
});

const DeleteProject = ({
  isOpen,
  onClose,
  onDelete,
  projectId,
  listType,
}: Props) => {
  const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
    update: deleteProjectUpdater(projectId, listType),
  });

  return (
    <DeleteModal
      title="Delete Project"
      data-context="delete-project-modal"
      isOpen={isOpen}
      onCancel={onClose}
      onDelete={() => {
        deleteProject({
          variables: {
            id: projectId,
          },
          optimisticResponse: deleteProjectOptimistic(projectId),
        });
        onDelete();
      }}
    >
      <Text as="h1" type="large">
        Are you sure you want to delete this project?
      </Text>
      <Text as="p" type="secondary">
        Deleting the project will also delete all of its analyses. The delete
        action is permanent.
      </Text>
    </DeleteModal>
  );
};

export default DeleteProject;
