// @flow
import React, { lazy, Suspense } from 'react';
import DelayRendering from 'components/DelayRendering';
import { Text } from '@quid/react-core';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';
import type { Props } from './NewAnalysis';
import { NavbarVisibilityProvider } from './NavbarVisibilityContext';

const NewAnalysis = lazy(() => import('./NewAnalysis'));

const Fallback = () => (
  <DelayRendering>
    <Scaffhold>
      <Navbar
        type="APPS"
        displayName={<Text.Skeleton>fullname</Text.Skeleton>}
      />
      <Container>
        <Main />
      </Container>
    </Scaffhold>
  </DelayRendering>
);

export default (props: Props) => (
  <Suspense fallback={<Fallback />}>
    <NavbarVisibilityProvider>
      <NewAnalysis {...props} />
    </NavbarVisibilityProvider>
  </Suspense>
);
