// @flow
import React, { lazy, Suspense } from 'react';
import DelayRendering from 'components/DelayRendering';
import { ThemeProvider } from '@quid/theme';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';

const Output = lazy(() => import('./Output'));

const Fallback = () => (
  <ThemeProvider theme="dark">
    <DelayRendering>
      <Scaffhold background="dark" data-theme="dark">
        <Navbar type="APPS" />
        <Container>
          <Main />
        </Container>
      </Scaffhold>
    </DelayRendering>
  </ThemeProvider>
);

// flowlint-next-line unclear-type:warn
export default (props: any) => (
  <Suspense fallback={<Fallback />}>
    <Output {...props} />
  </Suspense>
);
