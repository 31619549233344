// @flow
import type { Project } from 'types/Project';
import type { User, Role } from 'types/User';
export const APPS_LIMITED_TRIAL_USER = 'apps_limited_trial_user';

export const canModify = ({ project, me }: { project: Project, me: User }) =>
  !!project.owner && project.owner.id === me.id;

export const canShare = ({ roles }: { roles: Array<Role> }) =>
  !roles.some(role => role.name === APPS_LIMITED_TRIAL_USER);
