//@flow
import getYear from 'date-fns/get_year';
import getMonth from 'date-fns/get_month';
import getDate from 'date-fns/get_date';
import format from 'date-fns/format';
import pluralize from 'pluralize';
import type { Frequency } from 'types/Analysis';
import subWeeks from 'date-fns/sub_weeks';
import subMonths from 'date-fns/sub_months';

export const toDateString = (
  datetime: string | number | Date,
  options: Intl$DateTimeFormatOptions = {},
  local: boolean = false
) => {
  const date = new Date(datetime);

  const DEFAULT_OPTIONS: Intl$DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  if (!local) {
    DEFAULT_OPTIONS.timeZone = 'UTC';
  }

  return date.toLocaleDateString('en-us', {
    ...DEFAULT_OPTIONS,
    ...options,
  });
};

// Convert the date to "ISO-like" string, but preserving the timezone
export function dateToIsoString(date: Date) {
  const year = getYear(date);
  const month = String(getMonth(date) + 1).padStart(2, '0');
  const day = String(getDate(date)).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Convert any date to 'YYYY-MM-DD' with UTC timezone
export function localIsoToUtcIsoString(date: string | Date) {
  const d = new Date(date);
  return new Date(d.getTime() + d.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
}

export const FREQUENCIES = {
  HOURS: 'hour',
  DAYS: 'day',
  WEEKS: 'week',
  MONTHS: 'month',
  YEARS: 'year',
};

export const getFrequencylabel = ({ period, amount }: Frequency) => {
  if (!FREQUENCIES[period]) {
    throw new Error(`Unsupported frequency period provided: ${period}`);
  }
  return pluralize(FREQUENCIES[period], amount);
};

export const PERIOD_LABELS = [
  {
    id: 'WEEKS',
    label: 'week',
    short: 'w',
  },
  {
    id: 'MONTHS',
    label: 'month',
    short: 'm',
  },
];

export function presetToDate({
  amount,
  period,
}: {
  amount: number,
  period: 'WEEKS' | 'MONTHS',
}) {
  const sub = period === 'MONTHS' ? subMonths : subWeeks;
  return sub(new Date(), amount);
}

export type Preset = {
  amount: number,
  period: 'WEEKS' | 'MONTHS',
};

export function shortenPreset({ amount, period }: Preset): string {
  const selectedLabel = PERIOD_LABELS.find(({ id }) => id === period);
  return `${amount}${
    selectedLabel && selectedLabel.short ? selectedLabel.short : 'm'
  }`;
}

export function getLocalDate(date: Date): string {
  return format(date, 'YYYY-MM-DD');
}
