// @flow
import ApolloClient from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { toIdValue } from 'apollo-utilities';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities';

import { getErrorFromResponse } from 'utils/errors';

const useSsl = window.location.protocol === 'https:';
const HTTP = useSsl ? 'https:' : 'http:';
const WS = useSsl ? 'wss:' : 'ws:';

const HTTP_URI = '/api/v2/graphql';
const HTTP_ENDPOINT = `${HTTP}//${window.location.host}${HTTP_URI}`;
const WS_URI = '/api/ws';
const WS_ENDPOINT = `${WS}//${window.location.host}${WS_URI}`;

const addErrorToState = (state, response) => ({
  ...state,
  errors: [...state.errors, getErrorFromResponse(response)].filter(Boolean),
  authError:
    state.authError ||
    Boolean(response.networkError && response.networkError.statusCode === 401),
});

const {
  NetworkStatusNotifier,
  link: networkStatusNotifierLink,
} = createNetworkStatusNotifier({
  initialState: { errors: [], authError: false },

  reducers: {
    onRequest: state => state,
    onSuccess: (state, response) => {
      if (response.result && response.result.errors) {
        return addErrorToState(state, response);
      }

      return state;
    },
    onCancel: state => state,
    onError: (state, response) => {
      return addErrorToState(state, response);
    },
  },

  mapStateToProps: state => state,
});

const uploadLink = createUploadLink({ uri: HTTP_URI, credentials: 'include' });
const websocketClient = new SubscriptionClient(WS_ENDPOINT, {
  reconnect: true,
});
const websocketLink = new WebSocketLink(websocketClient);
const httpLink = createHttpLink({
  uri: HTTP_ENDPOINT,
});
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  websocketLink,
  ApolloLink.from([uploadLink, networkStatusNotifierLink, httpLink])
);

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'INTERFACE',
            name: 'VisualizationData',
            possibleTypes: [{ name: 'Network' }],
          },
          {
            kind: 'INTERFACE',
            name: 'Insight',
            possibleTypes: [{ name: 'BasicInsight' }, { name: 'EventInsight' }],
          },
        ],
      },
    },
  }),
  cacheRedirects: {
    Query: {
      bundle: (_, { id }) => toIdValue(`Bundle:${id}`),
      insight: (_, { id, type }) => {
        switch (type) {
          case 'BASIC':
            return toIdValue(`BasicInsight:${id}`);
          case 'EVENT':
            return toIdValue(`EventInsight:${id}`);
          default:
            return null;
        }
      },
    },
  },
}).restore(window.__APOLLO_STATE__);

const apolloClient = new ApolloClient<typeof cache>({ link, cache });

export { NetworkStatusNotifier };
export default apolloClient;
