// @flow
import nanoid from 'nanoid';

type GraphQLError = {
  operation: {
    operationName: string,
  },
  networkError?: {
    statusCode: number,
  },
};

export const ERROR_TYPE = {
  ERROR: 'error',
  WARNING: 'warning',
  SILENT: 'silent',
};

const reportMessage = ({ message, type }) => ({
  id: nanoid(),
  message,
  type,
});

const reportError = message =>
  reportMessage({
    message,
    type: ERROR_TYPE.ERROR,
  });

const reportSilently = (message = '') =>
  reportMessage({
    message,
    type: ERROR_TYPE.SILENT,
  });

export const getErrorFromResponse = (err: GraphQLError) => {
  if (err.networkError && err.networkError.statusCode === 401) {
    // In case of authentication error, we don't want to show an
    // error snackbar, but a "Redirecting to login..." screen
    return null;
  }

  switch (err.operation.operationName) {
    case 'getProjectAnalysesList':
      return reportError('There was an error fetching the analyses list.');
    case 'createProject':
      return reportError("We couldn't create the project.");
    case 'createCompetitiveAnalysis':
      return reportError('Analysis creation failed.');
    case 'deleteAnalysis':
      return reportError('Analysis deletion failed.');
    case 'shareProject':
      return reportError('Invalid e-mail address. Please check and try again.');
    case 'exportPPT':
      return reportError("We couldn't create the PPT file.");
    case 'exportAnalysis':
      return reportError("We couldn't export Analysis.");
    case 'competitorSuggestions':
      return reportSilently();
    default:
      return reportError(
        'An unexpected error occurred, please reload the page and try again.'
      );
  }
};
