//@flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { Logo as RCLogo } from '@quid-private/react-components';
import { Text, Icon } from '@quid/react-core';
import { colors } from '@quid/theme';
import Scaffhold from 'components/Scaffhold';
import { getErrorMessage } from 'screens/Analyses/Analyses.js';
import { GET_ANALYSIS_AND_ME_QUERY } from './queries';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import useRouter from 'use-react-router';
import AnalysisCard from 'components/AnalysisCard';
import useUpdateAnalysisSchedule, {
  type UpdateFactoryArgs,
  type ApolloCache,
} from 'screens/Analyses/mutations/updateAnalysisSchedule';
import { projectUrl } from 'utils/url';
import { useIntegrationContext } from 'components/IntegrationContext';
import { canSchedule, canDelete } from 'modules/authorization/analyses';
import { viewOutputUrl } from 'utils/url';
import { toDateString } from 'utils/date';
import { isAnalysisSuccessful } from 'screens/Analyses/Analyses';

const Logo = styled(RCLogo)`
  width: 82px;
  height: 44px;
  margin: 45px auto 30px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Main = styled.div`
  padding: 0 20px;
  display: flex;
  flex-basis: 1200px;
  flex-direction: column;
`;

const Description = styled(Text)`
  text-align: center;
  margin: 0 auto 5px;
`;

const Footer = styled.footer`
  margin: 30px 0;
  display: flex;
`;

const Header = styled.header`
  margin-bottom: 30px;
  text-align: center;
`;

const updateFactory = ({
  projectId,
  analysis,
  schedule,
}: UpdateFactoryArgs) => (cache: ApolloCache) => {
  const query = GET_ANALYSIS_AND_ME_QUERY;

  const data = cache.readQuery({
    query,
    variables: { projectId, analysisId: analysis.id },
  });

  const newData = {
    ...data,
    analysis: {
      ...data.analysis,
      schedule: {
        __typename: 'AnalysisSchedule',
        ...data.analysis.schedule,
        enabled:
          schedule.enabled != null
            ? schedule.enabled
            : data.analysis.schedule.enabled,
        frequency:
          schedule.frequency != null
            ? { __typename: 'ScheduleFrequency', ...schedule.frequency }
            : data.analysis.schedule.frequency,
      },
    },
  };

  cache.writeQuery({ query, data: newData });
};

const Analysis = ({
  analysis,
  projectId,
  canDeleteAnalysis,
  canScheduleAnalysis,
  canAccessSchedule,
  updateAnalysisScheduleFactory,
}) => {
  const [updateSchedule] = useUpdateAnalysisSchedule({
    projectId,
    analysis,
    updateAnalysisScheduleFactory,
  });

  const viewLink = isAnalysisSuccessful(analysis)
    ? viewOutputUrl({
        projectId: projectId,
        analysisId: analysis.id,
        outputId: String(analysis.outputs && analysis.outputs[0].id),
      })
    : undefined;

  return (
    <AnalysisCard
      id={analysis.id}
      data-context="analysis"
      type={analysis.type}
      title={analysis.title}
      createdAt={analysis.timestamps.createdAt}
      topics={
        analysis.intent.topics && analysis.intent.topics.map(({ text }) => text)
      }
      competitors={
        analysis.intent.competitors &&
        analysis.intent.competitors.map(({ text }) => text)
      }
      timeRange={[analysis.intent.period.from, analysis.intent.period.to]}
      viewLink={viewLink}
      canDelete={canDeleteAnalysis}
      canSchedule={canScheduleAnalysis}
      canAccessSchedule={canAccessSchedule}
      schedule={analysis.schedule}
      updateSchedule={updateSchedule}
      list={
        analysis.runs
          ? analysis.runs.map(run => ({
              to: run.output
                ? viewOutputUrl({
                    projectId,
                    analysisId: analysis.id,
                    outputId: run.output.id,
                  })
                : null,
              label: toDateString(run.startedAt),
              error: getErrorMessage(run),
            }))
          : []
      }
    />
  );
};

const AnalysisUnsubscribe = () => {
  const { match } = useRouter();
  const projectId = String(match.params.projectId);
  const analysisId = String(match.params.analysisId);
  const { LaunchDarkly } = useIntegrationContext();
  const { data: { analysis, me } = {}, loading } = useQuery(
    GET_ANALYSIS_AND_ME_QUERY,
    {
      variables: {
        analysisId: analysisId,
      },
    }
  );

  const canAccessSchedule = LaunchDarkly.flag('apps-scheduler', false);
  const canDeleteAnalysis =
    !loading && canDelete({ project: analysis.project, me });
  const canScheduleAnalysis =
    !loading && canSchedule({ project: analysis.project, me });

  return (
    <Scaffhold>
      <Logo color={colors.gray5} noDefaultStyle />
      <Container>
        <Main>
          <Header>
            <Description as="p" type="large">
              Change your analysis settings
            </Description>
            <Text type="secondary">
              Turn off scheduler to not receive emails.
            </Text>
          </Header>
          <div>
            {loading ? (
              <AnalysisCard.Skeleton />
            ) : (
              <Analysis
                analysis={analysis}
                projectId={projectId}
                canDeleteAnalysis={canDeleteAnalysis}
                canScheduleAnalysis={canScheduleAnalysis}
                canAccessSchedule={canAccessSchedule}
                updateAnalysisScheduleFactory={updateFactory}
              />
            )}
          </div>
          <Footer>
            <Link
              data-context="navigate-back"
              to={projectUrl({ projectId, type: 'APPS' })}
            >
              <Text type="secondary">
                <Icon name="caret_left" /> Go to project
              </Text>
            </Link>
          </Footer>
        </Main>
      </Container>
    </Scaffhold>
  );
};

export default AnalysisUnsubscribe;
