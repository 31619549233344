// @flow
export function genUrlFromParams(
  projectId?: ?string,
  analysisId?: ?string,
  outputId?: ?string,
  bundleId?: ?string,
  insightGroupId?: ?string,
  insightId?: ?string
) {
  let url = '/';

  if (projectId) {
    url += `apps/projects/${projectId}`;

    if (analysisId) {
      url += `/analyses/${analysisId}`;

      if (outputId) {
        url += `/view/${outputId}`;

        if (bundleId) {
          url += `/package/${bundleId}`;

          if (insightGroupId) {
            url += `/group/${insightGroupId}`;

            if (insightId) {
              url += `/insights/${insightId}`;
            }
          }
        }
      }
    }
  }

  return url;
}

export function getQuidProUrl(location?: string, path?: string = 'projects') {
  const url = new URL(location || window.location.origin);
  const prodRegexp = /(apps.|home.)/i;
  const devOrStagingRegexp = /(-apps|-home)/i;

  if (url.origin.match(prodRegexp)) {
    return `${url.origin.replace(prodRegexp, 'i.')}/${path}`;
  }

  if (url.origin.match(devOrStagingRegexp)) {
    return `${url.origin.replace(devOrStagingRegexp, '-i.')}/${path}`;
  }

  return `https://develop-i.quid.com/${path}`;
}
