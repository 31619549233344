// @flow
import React, { type Node } from 'react';
import { ThemeProvider } from '@quid/theme';
import { NavBar as QRNavbar } from '@quid/react-layouts';
import styled from '@emotion/styled/macro';
import Breadcrumb, {
  type GenerateBreadcrumbItems,
} from 'components/Breadcrumb';
import OptionsMenu from 'components/OptionsMenu';

const Container = styled(QRNavbar)`
  background-color: ${props => props.theme.colors.gray7};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  z-index: 1;
  color: ${props => props.theme.colors.gray2};
  flex-shrink: 0;
`;

const NavbarLeft = styled.div`
  display: flex;
  flexdirection: column;
`;

type Props = {
  type?: 'APPS' | 'PRO',
  displayName?: Node,
  tools?: Node,
  navigation?: Node,
  generateBreadcrumbItems?: GenerateBreadcrumbItems,
};

const Navbar = ({
  displayName,
  tools,
  type,
  navigation,
  generateBreadcrumbItems,
  ...props
}: Props) => {
  return (
    <ThemeProvider theme="dark">
      <Container
        data-context="navbar"
        breadcrumb={
          <NavbarLeft>
            <Breadcrumb
              type={type}
              generateBreadcrumbItems={generateBreadcrumbItems}
            />
            {navigation}
          </NavbarLeft>
        }
        tools={<OptionsMenu />}
        {...props}
      />
    </ThemeProvider>
  );
};

export default Navbar;
