// @flow
// all colors set with opacity 0.3
import { ANALYSIS_TYPES } from 'utils/analysis';
import Color from 'color';
import type { AnalysisTypes } from 'types/AnalysisTypes';

export const getColorByType = (type: AnalysisTypes) => {
  return Color(ANALYSIS_TYPES[type].color)
    .alpha(0.3)
    .string();
};

export const engineColors = [
  { r: 30, g: 215, b: 209 }, // Aqua
  { r: 240, g: 78, b: 83 }, // Coral
  { r: 135, g: 196, b: 69 }, // Lime
  { r: 168, g: 73, b: 154 }, // Purple
  { r: 255, g: 206, b: 3 }, // Yellow
  { r: 60, g: 135, b: 144 }, // Teal
  { r: 244, g: 164, b: 103 }, // Peach
  { r: 230, g: 30, b: 39 }, // Red
  { r: 191, g: 128, b: 146 }, // Rose
  { r: 3, g: 152, b: 73 }, // Green
  { r: 166, g: 222, b: 243 }, // Sky
  { r: 154, g: 96, b: 81 }, // Brown
  { r: 234, g: 233, b: 98 }, // Lemon
  { r: 178, g: 34, b: 100 }, // Magenta
  { r: 193, g: 150, b: 107 }, // Tan
  { r: 0, g: 128, b: 79 }, // Emerald
  { r: 149, g: 129, b: 188 }, // Lilac
  { r: 249, g: 98, b: 30 }, // Orange
  { r: 46, g: 86, b: 188 }, // Blue
  { r: 215, g: 46, b: 142 }, // Pink
];
