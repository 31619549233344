// @flow
import { useState, useCallback } from 'react';

type UseStateUpdater = <S, U>(
  initialState: (() => S) | S,
  updater: (S) => U => S
) => [S, (U) => void];

const useStateUpdater: UseStateUpdater = ((initialState, updater) => {
  const [state, setState] = useState(initialState);
  const update = useCallback(
    (...args) => setState(state => updater(state)(...args)),
    [updater]
  );
  return [state, update];
// flowlint-next-line unclear-type:warn
}: any);

export default useStateUpdater;
