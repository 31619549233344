// @flow
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';
import {
  ButtonGroup,
  Button as GroupableButton,
} from '@quid-private/react-components';
import FileTreeList from 'components/FileTreeList';
import type { Schedule } from 'types/Analysis';
import type { Frequency } from 'types/Analysis';
import useFrequencyButtonState from 'utils/useFrequencyButtonState';
import { getFrequencylabel } from 'utils/date';

const SchedulerConfig = styled.div`
  margin-right: auto;
`;

const SchedulerTitle = styled(Text)`
  margin-top: ${({ theme: { sizes } }) => sizes.large};
  margin-bottom: ${({ theme: { sizes } }) => sizes.large};
`;

const SchedulerList = styled.div`
  margin-top: ${({ theme: { sizes } }) => `calc(${sizes.large} * -2)`};
  width: 20%;
`;

const UpdateMessage = styled(Text)`
  margin-top: ${({ theme: { sizes } }) => sizes.regular};
`;

type Props = {
  list: Array<{ to?: string, label: string }>,
  schedule: $Shape<Schedule>,
  setSchedule: ($Shape<Schedule>) => void,
  scheduleFrequencies: Array<Frequency>,
  disabled: boolean,
};

const serializeFrequency = ({ amount, period }) => `${amount}${period}`;

const Scheduler = styled(
  ({
    list,
    schedule,
    setSchedule,
    scheduleFrequencies,
    disabled,
    ...props
  }: Props) => {
    const [
      frequenciesState,
      buttonsState,
      onButtonStateChange,
    ] = useFrequencyButtonState({
      frequencies: scheduleFrequencies,
      frequency: schedule.frequency,
    });
    const [timeFrequencyChanged, setTimeFrequencyChanged] = useState(false);
    const [updateMessage, setUpdateMessage] = useState(null);
    const timeFrequency = schedule.frequency
      ? serializeFrequency(schedule.frequency)
      : null;

    useEffect(() => {
      if (!timeFrequencyChanged) {
        return;
      }

      setUpdateMessage('Your timeframe has changed');
      const timer = setTimeout(() => setUpdateMessage(null), 3000);
      return () => clearTimeout(timer);
    }, [timeFrequency, timeFrequencyChanged]);

    const noList = [
      {
        label: (
          <Text type="secondary">
            No schedule generated analyses are available yet.
          </Text>
        ),
      },
    ];

    return (
      <div {...props}>
        <SchedulerConfig>
          <Text as="p" type="secondary">
            Automatically rerun analysis and receive email notifications.
          </Text>

          {scheduleFrequencies.length > 1 ? (
            <>
              <SchedulerTitle as="h2" type="large secondary">
                Change Frequency
              </SchedulerTitle>

              <ButtonGroup
                buttonsState={buttonsState}
                onToggle={onButtonStateChange(({ amount, period }) => {
                  setTimeFrequencyChanged(true);
                  setSchedule({ frequency: { amount, period } });
                })}
                size="small"
                importance="secondary"
                data-context="frequency-selector"
                data-value={timeFrequency}
              >
                {frequenciesState.map(({ name, amount, period }) => {
                  return (
                    <GroupableButton
                      name={name}
                      key={name}
                      data-action="change-frequency"
                      data-value={serializeFrequency({ amount, period })}
                      disabled={disabled}
                    >
                      {amount} {getFrequencylabel({ amount, period })}
                    </GroupableButton>
                  );
                })}
              </ButtonGroup>
              {updateMessage && (
                <UpdateMessage as="p" type="secondary">
                  {updateMessage}
                </UpdateMessage>
              )}
            </>
          ) : (
            <SchedulerTitle as="p" type="secondary">
              Scheduled to run every {scheduleFrequencies[0].amount}{' '}
              {getFrequencylabel({
                amount: scheduleFrequencies[0].amount,
                period: scheduleFrequencies[0].period,
              })}
            </SchedulerTitle>
          )}
        </SchedulerConfig>
        <SchedulerList>
          <FileTreeList
            data-context="schedule-generated-runs-list"
            list={list.length > 0 ? list : noList}
          />
        </SchedulerList>
      </div>
    );
  }
)`
  flex: 1;
  display: flex;
`;

export default Scheduler;
