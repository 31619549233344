// @flow
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { resolveTypename } from 'utils/analysis';
import type { Analysis, Schedule } from 'types/Analysis';
import { GET_PROJECT_ANALYSES_QUERY } from '../queries';

const UPDATE_ANALYSIS_SCHEDULE = gql`
  mutation updateAnalysisSchedule(
    $analysisId: ID!
    $payload: AnalysisScheduleInput!
  ) {
    updateAnalysisSchedule(analysisId: $analysisId, payload: $payload) {
      id
      schedule {
        enabled
        frequency {
          amount
          period
        }
      }
    }
  }
`;

const optimisticResponseFactory = ({ type, id }: Analysis) => ({
  __typename: 'Mutation',
  updateAnalysisSchedule: {
    __typename: resolveTypename(type),
    id,
  },
});

export type UpdateFactoryArgs = {
  projectId: string,
  analysis: Analysis,
  schedule: Schedule,
};
export type ApolloCache = {
  // flowlint-next-line unclear-type:warn
  readQuery: any => any,
  // flowlint-next-line unclear-type:warn
  writeQuery: any => void,
};
export const updateFactory = ({
  projectId,
  analysis,
  schedule,
}: UpdateFactoryArgs) => (cache: ApolloCache) => {
  const query = GET_PROJECT_ANALYSES_QUERY;

  const data = cache.readQuery({
    query,
    variables: { projectId, analysisId: analysis.id },
  });

  const updatedAnalyses = data.project.analyses.items.map(current => {
    if (current.id === analysis.id) {
      return {
        ...current,
        schedule: {
          __typename: 'AnalysisSchedule',
          ...current.schedule,
          enabled:
            schedule.enabled != null
              ? schedule.enabled
              : current.schedule.enabled,
          frequency:
            schedule.frequency != null
              ? { __typename: 'ScheduleFrequency', ...schedule.frequency }
              : current.schedule.frequency,
        },
      };
    }
    return current;
  });

  const newData = {
    ...data,
    project: {
      ...data.project,
      analyses: {
        ...data.project.analyses,
        items: updatedAnalyses,
      },
    },
  };

  cache.writeQuery({ query, data: newData });
};

type Variables = { analysisId: string, payload: $Shape<Schedule> };

const useUpdateAnalysisSchedule = ({
  projectId,
  analysis,
  updateAnalysisScheduleFactory,
}: {
  projectId: string,
  analysis: Analysis,
  updateAnalysisScheduleFactory?: UpdateFactoryArgs => ApolloCache => void,
}) => {
  const [updateAnalysisSchedule] = useMutation(UPDATE_ANALYSIS_SCHEDULE, {
    optimisticResponse: optimisticResponseFactory(analysis),
  });

  return [
    ({ variables }: { variables: Variables }) => {
      const updateHandler = updateAnalysisScheduleFactory || updateFactory;
      updateAnalysisSchedule({
        variables,
        update: updateHandler({
          projectId,
          analysis,
          schedule: variables.payload,
        }),
      });
    },
  ];
};

export default useUpdateAnalysisSchedule;
