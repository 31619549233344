//@flow
import styled from '@emotion/styled/macro';
import { Text } from '@quid/react-core';

const Ellipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Ellipsis;
