// @flow
import gql from 'graphql-tag';

//TODO we might want to fix having AppsProject query
export const GET_PROJECT_ANALYSES_QUERY = gql`
  query getProjectAnalysesList($projectId: ID!) {
    project(id: $projectId) {
      name
      id
      timestamps {
        createdAt
        updatedAt
      }
      sharedWith {
        id
        firstName
        lastName
        fullName
        email
      }
      ... on AppsProject {
        public
        analyses {
          totalCount
          items {
            id
            type
            timestamps {
              createdAt
            }
            title
            schedule {
              enabled
              frequency {
                amount
                period
              }
            }
            intent {
              ... on CompetitiveIntent {
                topics {
                  text
                }
                competitors {
                  text
                }
              }
              ... on ContentWhitespaceIntent {
                topics {
                  text
                }
              }
              ... on KOLIntent {
                topics {
                  text
                }
              }
              ... on HeadlinesIntent {
                topics {
                  text
                }
              }
              period {
                from
                to
              }
            }
            outputs {
              id
            }
            runs {
              state
              startedAt
              scheduled
              output {
                id
              }
            }
            project {
              ... on AppsProject {
                id # Workaround for https://github.com/apollographql/react-apollo/issues/1314#issuecomment-374773268
                public
              }
            }
          }
        }
      }
      owner {
        id
        fullName
      }
    }
    me {
      id
      fullName
      roles {
        name
      }
    }
  }
`;
