// @flow
import React from 'react';
import { Route } from 'react-router-dom';
import type { Match } from 'react-router';
import AnalysisFromRun from './AnalysisFromRun';
import AnalysisUnsubscribe from './AnalysisUnsubscribe';
import { NotFound } from '../ErrorMessage';

const DeepLink = ({ match }: { match: Match }) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/runs/:runId`}
        component={AnalysisFromRun}
      />
      <Route
        exact
        path={`${match.path}/analyses/:analysisId/unsubscribe`}
        component={AnalysisUnsubscribe}
      />
      <NotFound />
    </>
  );
};

export default DeepLink;
