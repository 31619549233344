// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import UserAvatar from 'components/UserAvatar';
import { Text, Button, Icon } from '@quid/react-core';

const Main = styled.div`
  margin-left: 1.42em;
  margin-right: 1.42em;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

const FullName = styled(Text)`
  margin-bottom: 0.38em;
`;

type Props = {
  fullName: string,
  email: string,
  className?: string,
  // flowlint-next-line unclear-type:warn
  onUnshare: Function,
};

export const UserRow = ({ fullName, email, className, onUnshare }: Props) => (
  <div className={className}>
    <UserAvatar fullName={fullName} data-pii="true" />
    <Main>
      <FullName as="p" data-pii="true">
        {fullName}
      </FullName>
      <Text as="p" type="secondary" data-pii="true">
        {email}
      </Text>
    </Main>
    <Button onClick={onUnshare}>
      <Icon name="close" />
    </Button>
  </div>
);

const StyledUserRow = styled(UserRow)`
  display: flex;
  align-items: center;
  padding: 1.42em 0.71em;
  border-top: 1px solid var(--gray1);
  &:last-child {
    border-bottom: 1px solid var(--gray1);
  }
`;

export default StyledUserRow;
