// @flow
import { useCallback } from 'react';
import type { Frequency, FrequencyPeriod } from 'types/Analysis';

type ButtonState = {
  __typename?: string,
  name: string | number,
  amount: number,
  period: FrequencyPeriod,
  isActive: boolean,
};

type Props = {
  frequencies: Array<Frequency>,
  frequency: ?Frequency,
};

const useFrequencyButtonState = ({ frequencies, frequency }: Props) => {
  const frequenciesState: Array<ButtonState> = frequencies.map(
    ({ amount, period }) => ({
      name: `${amount}${period}`,
      amount,
      period,
      isActive:
        frequency != null
          ? frequency.amount === amount && frequency.period === period
          : false,
    })
  );

  const buttonsState = frequenciesState.map<{
    name: string | number,
    isActive?: boolean,
  }>(({ name, isActive }) => ({
    name,
    isActive,
  }));

  const onButtonStateChange = useCallback(
    (callback: Frequency => void) => (name: string | number) => {
      const option = frequenciesState.find(opt => opt.name === name);
      if (option) {
        callback(option);
      }
    },
    [frequenciesState]
  );

  return [frequenciesState, buttonsState, onButtonStateChange];
};

export default useFrequencyButtonState;
