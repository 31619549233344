// @flow
import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  type Node,
} from 'react';
import { integrateServicesFor } from '@quid-private/integrations';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { type HeapAnalyticsType } from 'utils/analytics';
import RedirectToLogin from './RedirectToLogin';
import type { IntegrationData } from 'types/IntegrationData';

type LaunchDarklyType = {
  allFlags: string => Array<string>,
  // flowlint-next-line unclear-type:warn
  flag: (string, any) => any,
};

type IntegrationsContextType = {
  LaunchDarkly: LaunchDarklyType,
  HeapAnalytics: ?HeapAnalyticsType,
};

const INTEGRATIONS_CONFIG = gql`
  query integrationsConfig {
    configuration {
      bugsnag {
        apiKey
        enabled
      }
      googleAnalytics {
        trackingId
      }
      heapAnalytics {
        key
      }
      qualaroo {
        clientId
      }
      walkMe {
        apiKey
        enabled
      }
      fullStory {
        host
        org
        namespace
        debug
        enabled
      }
      launchDarkly {
        clientId
        flags
      }
      environment
      zendeskChat {
        enabled
        hide
      }
    }
    me {
      id
      email
      fullName
      roles {
        name
      }
      client {
        name
        id
      }
      createdAt
    }
  }
`;

const initialLaunchDarkly = {
  allFlags: () => [],
  flag: (name, defaultValue = false) => defaultValue,
};

const initialContextValue = {
  LaunchDarkly: initialLaunchDarkly,
  HeapAnalytics: undefined,
};

const IntegrationsContext = createContext<IntegrationsContextType>(
  initialContextValue
);

export const useIntegrationContext = () => useContext(IntegrationsContext);

function hasValidLaunchdarklyFlags(LaunchDarkly) {
  const flags = !!LaunchDarkly && LaunchDarkly.allFlags();
  return !flags ||
    (typeof flags === 'object' && Object.keys(flags).length === 0)
    ? false
    : true;
}

export const IntegrationProvider = ({ children }: { children: Node }) => {
  const { data, loading, error } = useQuery(INTEGRATIONS_CONFIG);
  const hasData = data && Object.keys(data).length > 0;

  const [integrations, setIntegrations] = useState({
    LaunchDarkly: initialLaunchDarkly,
    HeapAnalytics: undefined,
  });
  const [isIntegrationsValid, setIntegrationsValidity] = useState(true);
  const [redirectLabel, setRedirectLabel] = useState('Redirecting to login');

  useEffect(
    () =>
      void (async () => {
        if (error) {
          setIntegrationsValidity(false);
          return;
        }

        if (loading || !hasData) {
          return;
        }

        try {
          const integrations = await integrateServicesFor(
            null,
            (data: ?IntegrationData)
          );
          setIntegrations(integrations);
          setIntegrationsValidity(
            isIntegrationsValid =>
              isIntegrationsValid &&
              hasValidLaunchdarklyFlags(integrations.LaunchDarkly)
          );
        } catch (error) {
          setIntegrationsValidity(false);
          if (error.networkError) {
            setRedirectLabel('Please authenticate with ScaleFT');
          }
        }
      })(),
    [data, error, hasData, loading]
  );

  return isIntegrationsValid ? (
    <IntegrationsContext.Provider value={integrations}>
      {children}
    </IntegrationsContext.Provider>
  ) : (
    <RedirectToLogin label={redirectLabel} />
  );
};
