// @flow
import { type Component } from 'react';
import styled from '@emotion/styled/macro';

type Props = {
  small?: boolean,
};

const Spacer: Class<Component<Props>> = styled.span`
  display: inline-block;
  width: ${props => (props.small ? '0.38em' : '0.769em')};
`;

export default Spacer;
