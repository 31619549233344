// @flow
import React, { useState } from 'react';
import { Logo, ContextMenu } from '@quid-private/react-components';
import { Button, Icon, Text } from '@quid/react-core';
import { ThemeProvider } from '@quid/theme';
import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/core';
import { getQuidProUrl } from 'modules/util';
import { useIntegrationContext } from 'components/IntegrationContext';
import { SettingsMenu } from '../Home';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.gray6};
`;

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 10px;
  background-color: ${props => props.theme.colors.gray7};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  z-index: 1;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px 0;
`;

const StyledContextMenu = styled(ContextMenu)`
  z-index: 3;
`;

const Title = styled(Text)`
  font-size: 32px;
  color: ${props => props.theme.colors.white};
  width: 447px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  margin: 32px 0 16px;
`;

const Message = styled(Text)`
  font-size: 20px;
  color: ${props => props.theme.colors.white};
  width: 760px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  margin: 16px 0;
`;

const HorizontalDivider = styled.div`
  width: 760px;
  height: 2px;
  background-color: ${props => props.theme.colors.gray5};
  margin: 16px 0 4px;
`;

const StyledLink = styled.a`
  color: var(--link);
  font-weight: bold;
  &:hover {
    color: var(--link);
    text-decoration: underline;
  }
`;

const SunsetPage = () => {
  const [isSettingsMenuOpen, setSettingsMenuState] = useState(false);
  const { LaunchDarkly } = useIntegrationContext();
  const resourceCenterURL = LaunchDarkly.flag('help-center-url-quid', '');
  return (
    <ThemeProvider theme="dark">
      <Container>
        <Global
          styles={css`
            html,
            body,
            #root {
              height: 100%;
            }
          `}
        />
        <NavBarContainer>
          <a href={getQuidProUrl()}>
            <Logo />
          </a>
          <StyledContextMenu
            menu={<SettingsMenu />}
            isOpen={isSettingsMenuOpen}
            placement="bottom-end"
            popperModifiers={{
              preventOverflow: { enabled: false },
              hide: { enabled: false },
            }}
            onToggle={setSettingsMenuState}
          >
            <Button>
              <Icon name="gear" />
            </Button>
          </StyledContextMenu>
        </NavBarContainer>
        <SubContainer>
          <img src={require('./img/AppsSunset.svg')} alt="Quid Pro" />
          <Title>Quid Apps was discontinued on February 16, 2022</Title>
          <Message>
            You can easily uncover the same media-based insights you found using
            Quid Apps with Quid Pro. Check out the{' '}
            <StyledLink
              href={`${resourceCenterURL}?cid=010`}
              target="_blank"
              rel="noreferrer"
            >
              Transitioning from Quid Apps to Quid Pro
            </StyledLink>{' '}
            guide on NBQ360 for instructions.
          </Message>
          <HorizontalDivider />
          <Message>
            Questions? Our Support team is here to help! Send us a note at{' '}
            <a style={{ fontWeight: 'bold' }} href="mailto:support@quid.com">
              support@quid.com
            </a>
          </Message>
        </SubContainer>
      </Container>
    </ThemeProvider>
  );
};

export default SunsetPage;
