// @flow
import React, { Fragment, type Node } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Text } from '@quid/react-core';
import RemoveTrailingSlash from 'components/RemoveTrailingSlash';
import { getQuidProUrl } from 'modules/util';
import Projects from '../Projects';
import AnalysisUnsubscribe from '../AnalysisUnsubscribe';
import Analyses from '../Analyses';
import Portfolios from '../Portfolios';
import NewAnalysis from '../NewAnalysis';
import Admin from '../Admin';
import NewSearch from '../NewSearch';
import Output from '../Output';
import SunsetPage from '../SunsetPage';
import { Forbidden, NotFound } from '../ErrorMessage';
import DeepLink from '../DeepLink';
import { NetworkStatusNotifier } from 'modules/apollo';
import ErrorBoundary from 'components/ErrorBoundary';
import RedirectToLogin from 'components/RedirectToLogin';
import { ThemeProvider } from '@quid/theme';
import { CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';
import focusVisiblePlugin from '@quid/stylis-plugin-focus-visible';
import { useIntegrationContext } from 'components/IntegrationContext';
import SystemMessages from './SystemMessages';
import { FlashMessageContext } from 'context/FlashMessageContext';
import useAccessControl from 'utils/useAccessControl';
import { generateRandomId } from 'utils/data';

const emotionCache = createCache({
  stylisPlugins: [focusVisiblePlugin],
});

// TODO: replace this with simpler logic once we upgrade to react-apollo-network-status@2
//       right now we are blocked by https://github.com/molindo/react-apollo-network-status/issues/15
const AuthErrorHandler = ({ children }: { children: Node }) => (
  <NetworkStatusNotifier
    render={({ authError }) => (authError ? <RedirectToLogin /> : children)}
  />
);

const ProtectedRoute = ({
  component: Component,
  loadingUserAccess,
  authorized,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      loadingUserAccess ? null : authorized ? (
        <Component {...props} />
      ) : (
        <Forbidden />
      )
    }
  />
);

const useFlashMessage = () => {
  const [flashMessages, setMessage] = React.useState([]);

  const setFlashMessage = (message: string): void => {
    setMessage([...flashMessages, { message, id: generateRandomId() }]);
  };

  return {
    flashMessages,
    setFlashMessage,
  };
};

const App = () => {
  const { LaunchDarkly } = useIntegrationContext();
  /**
   * FIXME: default set to true to avoid a flash of 404 page as the FF is being fetched,
   * relying on server side FF authorization.
   */
  const canAccessProSearch = LaunchDarkly.flag('quid-apps-pro-search', true);
  const canAccessProPMT =
    LaunchDarkly.flag('apps-pmt-projects-type', 'APPS') !== 'APPS';

  const hasAdminFeatureFlag = LaunchDarkly.flag('quid-apps-admin-page', true);
  const directAccess = LaunchDarkly.flag('quid-apps-direct-access', false);
  const {
    loadingUserAccess,
    hasAdminAccess,
    hasAppsAccess,
    hasProAccess,
  } = useAccessControl();
  const flashMessages = useFlashMessage();
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme="light">
        <FlashMessageContext.Provider value={flashMessages}>
          <Router>
            <AuthErrorHandler>
              <SystemMessages />

              <ErrorBoundary
                errorMessage={
                  <Fragment>
                    Aw, Snap! Something went wrong.
                    <br />
                    Please contact{' '}
                    <Text
                      as="a"
                      type="link bold xlarge"
                      href="mailto:support@quid.com"
                    >
                      support@quid.com
                    </Text>
                  </Fragment>
                }
              >
                <RemoveTrailingSlash />
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => {
                      if (hasProAccess) {
                        window.location = getQuidProUrl();
                      } else if (hasAppsAccess) {
                        return <SunsetPage />;
                      }
                    }}
                  />
                  <Route
                    path="/access-denied"
                    exact
                    render={() => {
                      window.location = getQuidProUrl(null, 'access-denied');
                    }}
                  />
                  )
                  <ProtectedRoute
                    path="/admin"
                    component={Admin}
                    loadingUserAccess={loadingUserAccess}
                    authorized={hasAdminFeatureFlag && hasAdminAccess}
                  />
                  {directAccess && hasAppsAccess ? (
                    [
                      <Route
                        path="/apps/projects/new"
                        render={props => <Projects {...props} showCreate />}
                      />,

                      <Route
                        path="/apps/projects/:projectId/delete"
                        render={props => <Projects {...props} showDelete />}
                      />,

                      <Route
                        path="/apps/projects/:projectId"
                        render={({ match }) => (
                          <Switch>
                            <Route
                              path={`${match.path}/portfolios`}
                              render={({ match }) => (
                                <Switch>
                                  {canAccessProSearch && (
                                    <Route
                                      path={`${match.path}/new`}
                                      render={({ match }) => (
                                        <NewSearch
                                          projectId={
                                            /* $FlowFixMe: Added when we enabled
                                             * unclear-type lint rule */
                                            ((match.params
                                              .projectId: any): string)
                                          }
                                        />
                                      )}
                                    />
                                  )}
                                  {canAccessProSearch && (
                                    <Route
                                      path={`${match.path}/:portfolioId`}
                                      render={({ match: { params } }) => (
                                        <NewSearch
                                          projectId={
                                            /* $FlowFixMe: Added when we enabled
                                             * unclear-type lint rule */
                                            ((params.projectId: any): string)
                                          }
                                          portfolioId={
                                            /* $FlowFixMe: Added when we enabled
                                             * unclear-type lint rule */
                                            ((params.portfolioId: any): string)
                                          }
                                        />
                                      )}
                                    />
                                  )}
                                  )}
                                  {canAccessProPMT ? (
                                    <Route
                                      path={match.path}
                                      component={Portfolios}
                                    />
                                  ) : (
                                    <Route
                                      render={() =>
                                        window.location.replace(
                                          `${getQuidProUrl()}/${
                                            /* $FlowFixMe: Added when we enabled
                                             * unclear-type lint rule */
                                            ((match.params
                                              .projectId: any): string)
                                          }/details`
                                        ) || null
                                      }
                                    />
                                  )}
                                </Switch>
                              )}
                            />
                            <Route
                              path={`${match.path}/analyses`}
                              render={({ match }) => (
                                <Switch>
                                  <Route
                                    path={`${match.path}/new`}
                                    render={({ match }) => (
                                      <NewAnalysis
                                        projectId={
                                          /* $FlowFixMe: Added when we enabled
                                           * unclear-type lint rule */
                                          ((match.params
                                            .projectId: any): string)
                                        }
                                        isMainPage={match.isExact}
                                      />
                                    )}
                                  />

                                  <Route
                                    path={`${match.path}/:analysisId/unsubscribe`}
                                    component={AnalysisUnsubscribe}
                                  />

                                  <Route
                                    path={`${match.path}/:analysisId/view/:outputId/package`}
                                    render={({ match }) => (
                                      <Switch>
                                        <Route
                                          path={`${match.path}/:bundleId/group/:insightGroupId`}
                                          render={props => (
                                            <Output {...props} />
                                          )}
                                        />

                                        <Route
                                          path={`${match.path}/:bundleId`}
                                          render={props => (
                                            <Output {...props} />
                                          )}
                                        />
                                      </Switch>
                                    )}
                                  />

                                  <Route
                                    path={`${match.path}/:analysisId/view/:outputId/package/:bundleId`}
                                    render={props => <Output {...props} />}
                                  />

                                  <Route
                                    path={`${match.path}/:analysisId/view/:outputId`}
                                    render={props => <Output {...props} />}
                                  />

                                  <Route
                                    path={match.path}
                                    component={Analyses}
                                  />
                                </Switch>
                              )}
                            />
                          </Switch>
                        )}
                      />,

                      <Route path="/apps/projects" component={Projects} />,
                      <Route path="/go" component={DeepLink} />,
                    ]
                  ) : (
                    <Route path="/" component={SunsetPage} />
                  )}
                  {/* Redirects to the proxy login page in development mode */}
                  {process.env.NODE_ENV === 'development' && (
                    <Route
                      exact
                      path="/login"
                      render={() => {
                        window.location = 'http://localhost:8000/login';
                        return 'Redirecting to the development login page...';
                      }}
                    />
                  )}
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </AuthErrorHandler>
          </Router>
        </FlashMessageContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};
export default App;
