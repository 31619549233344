// @flow
import React, { type Node } from 'react';

type NavbarVisibilityContextType = {
  navbarVisible: boolean,
  setVisibility: boolean => void,
};

const defaultContextValue = {
  navbarVisible: true,
  setVisibility: visibility => {},
};

const Context = React.createContext<NavbarVisibilityContextType>(
  defaultContextValue
);

export const NavbarVisibilityProvider = ({ children }: { children: Node }) => {
  const [navbarVisible, setVisibility] = React.useState(true);
  return (
    <Context.Provider value={{ navbarVisible, setVisibility }}>
      {children}
    </Context.Provider>
  );
};

export const useNavbarVisibilityContext = () => React.useContext(Context);
