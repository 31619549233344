// @flow
import { useState, useCallback, useEffect } from 'react';

const useDocumentHidden = () => {
  const [hidden, setHidden] = useState(document.hidden);
  const eventHandler = useCallback(() => setHidden(document.hidden), []);
  useEffect(() => {
    document.addEventListener('visibilitychange', eventHandler);
    return () => document.removeEventListener('visibilitychange', eventHandler);
  }, [eventHandler]);
  return hidden;
};

export default useDocumentHidden;
