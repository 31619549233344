// @flow
import React from 'react';
import Loading from '../Loading';
import { NotFound } from '../ErrorMessage';
import { Redirect } from 'react-router-dom';
import type { Match } from 'react-router';
import { viewOutputUrl } from 'utils/url';
import { useQuery } from '@apollo/react-hooks';
import { useIntegrationContext } from 'components/IntegrationContext';
import gql from 'graphql-tag';

const GET_RUN_DETAILS = gql`
  query GetRunDetails($runId: ID!) {
    run(id: $runId) {
      output {
        id
        analysis {
          id
          project {
            id
          }
        }
      }
    }
  }
`;

const AnalysisFromRun = ({ match }: { match: Match }) => {
  const { data, loading, error } = useQuery(GET_RUN_DETAILS, {
    variables: {
      runId: match.params.runId,
    },
  });

  const { HeapAnalytics } = useIntegrationContext();

  if (loading) {
    return <Loading message="Redirecting to your analysis" />;
  }

  if (error) {
    return <NotFound />;
  }

  const {
    // $FlowIgnore: `run` is defined at this point
    run: {
      output: {
        id: outputId,
        analysis: {
          id: analysisId,
          project: { id: projectId },
        },
      },
    },
  } = data;

  if (HeapAnalytics) {
    HeapAnalytics.sendEvent('view-go-run', {
      projectId,
      analysisId,
      outputId,
    });
  }

  return (
    <Redirect
      to={viewOutputUrl({
        projectId,
        analysisId,
        outputId,
      })}
    />
  );
};

export default AnalysisFromRun;
