// @flow
import React, { forwardRef } from 'react';
import { Container } from '@quid/react-tooltip';
import styled from '@emotion/styled/macro';

const ErrorTooltip = styled(
  forwardRef((props, ref) => <Container {...props} ref={ref} />)
)`
  max-width: 220px;
  text-align: center;
`;

export default ErrorTooltip;
