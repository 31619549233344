// @flow
import React, { useState } from 'react';
import { Button } from '@quid/react-core';
import { Separator } from '@quid-private/react-components';
import styled from '@emotion/styled/macro';
import useRouter from 'use-react-router';
import { AutoSizer } from 'react-virtualized';
import chunk from 'lodash/fp/chunk';
import uniqBy from 'lodash/fp/uniqBy';
import { useIntegrationContext } from 'components/IntegrationContext';
import ProjectCard from 'components/ProjectCard';
import Navbar from 'components/Navbar';
import Scaffhold, { Container, Main } from 'components/Scaffhold';
import { useQuery, useMutation } from '@apollo/react-hooks';
import NewProjectModal from 'components/NewProjectModal';
import InfiniteLoaderGrid from 'components/InfiniteLoaderGrid';
import { PROJECTS_QUERY, CREATE_PROJECT_MUTATION } from './queries';
import ShareProject from 'screens/Projects/ShareProject';
import DeleteProject from 'screens/Projects/DeleteProject';
import { canModify, canShare } from 'modules/authorization/projects';
import useAnalyticsContext from 'utils/useAnalyticsContext';
import EmptyProject from './EmptyProject';

type Props = {
  showCreate?: boolean,
  showDelete?: boolean,
};

type GetPathProps = {
  id: string,
  type: string,
};

export const getPath = ({ id, type }: GetPathProps) =>
  `/apps/projects/${id}/${type === 'APPS' ? 'analyses' : 'portfolios'}`;

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  padding: 0.56em 0.74em;
  flex-shrink: 0;
`;

const Ghost = styled('div')`
  /* same size of ProjectCard */
  width: 26em;
  margin: 0 1.07em;
`;

const MainActionButton = styled(Button)`
  margin-left: auto;
`;

const Pad = styled.div`
  padding: 1.07em;
`;

const ListContainer = styled.div`
  flex: 1;
  min-height: 0;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CenteredInfiniteLoaderGrid = styled(InfiniteLoaderGrid)`
  display: flex;
  justify-content: center;
  &:not(:focus-visible) {
    outline: none;
  }
`;

const CARD_WIDTH = 364 + 15 * 2;
const CARD_HEIGHT = 247 + 15 * 2;

const getColsCount = width => Math.floor(width / CARD_WIDTH);

const handleCreateProjectButtonClick = async ({
  createProject,
  newProjectName,
  history,
  listType,
}) => {
  const { data } = await createProject({
    variables: {
      name: newProjectName,
    },
    refetchQueries: () => [
      { query: PROJECTS_QUERY, variables: { limit: 15, type: listType } },
    ],
  });

  history.push(String(data.createProject.id) + '/analyses');
};

const GridSkeleton = () => (
  <SkeletonContainer>
    {Array.from({ length: 10 }, (_, index) => (
      <Pad key={index}>
        <ProjectCard.Skeleton />
      </Pad>
    ))}
    {Array.from({ length: 10 }, (_, index) => (
      <Ghost key={index} />
    ))}
  </SkeletonContainer>
);

const loadMoreRows = ({ colsCount, fetchMore }) => ({
  startIndex,
  stopIndex,
}) => {
  const cursor = startIndex * colsCount;
  const limit = stopIndex * colsCount - cursor;
  return fetchMore({
    variables: {
      cursor,
      limit,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => ({
      ...previousResult,
      projects: {
        ...previousResult.projects,
        items: uniqBy('id')([
          ...previousResult.projects.items,
          ...fetchMoreResult.projects.items,
        ]),
      },
    }),
  });
};

const Projects = ({ showCreate = false, showDelete = false }: Props) => {
  const { history, match } = useRouter();
  const { LaunchDarkly } = useIntegrationContext();
  const listType = LaunchDarkly.flag('apps-pmt-projects-type', 'APPS');
  const showPublicProjects = LaunchDarkly.flag('apps-public-project', false);
  useAnalyticsContext();
  const [isShareManagerOpen, setShareManagerState] = useState<boolean>(false);
  const [shareManagerProjectId, setShareManagerProjectId] = useState<?string>(
    null
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [createProject] = useMutation(CREATE_PROJECT_MUTATION);
  const { data: { projects, me } = {}, loading, fetchMore } = useQuery(
    PROJECTS_QUERY,
    {
      variables: {
        limit: 15,
        type: listType,
      },
    }
  );

  return (
    <Scaffhold>
      <Navbar type="APPS" data-context="top-nav" />
      <Container>
        <NewProjectModal
          isOpen={showCreate}
          submitting={submitting}
          onSubmit={async () => {
            setSubmitting(true);
            await handleCreateProjectButtonClick({
              createProject,
              newProjectName: newProjectName,
              history,
              listType,
            });
            setSubmitting(false);
          }}
          value={newProjectName}
          onChange={e => setNewProjectName(e.target.value)}
        />
        <ShareProject
          isOpen={isShareManagerOpen}
          onClose={() => setShareManagerState(false)}
          project={
            !loading &&
            projects.items.find(({ id }) => id === shareManagerProjectId)
          }
        />
        {match.params.projectId && (
          <DeleteProject
            isOpen={showDelete}
            onClose={() => history.push('/apps/projects')}
            onDelete={() => history.push('/apps/projects')}
            projectId={match.params.projectId}
            listType={listType}
          />
        )}
        <Main data-context="main">
          <Header>
            <MainActionButton
              to={'/apps/projects/new'}
              importance="primary"
              data-action="open-create-project"
            >
              New Project
            </MainActionButton>
          </Header>
          <Separator />
          {!loading && projects.totalCount === 0 ? (
            <EmptyProject />
          ) : (
            <ListContainer data-context="project-list">
              {!loading ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <CenteredInfiniteLoaderGrid
                      infiniteLoaderProps={{
                        isRowLoaded: ({ index }) =>
                          Math.floor(
                            projects.items.length / getColsCount(width)
                          ) > index,
                        loadMoreRows: loadMoreRows({
                          colsCount: getColsCount(width),
                          fetchMore,
                        }),
                        rowCount: Math.ceil(
                          projects.totalCount / getColsCount(width)
                        ),
                      }}
                      height={height}
                      width={width}
                      columnWidth={CARD_WIDTH}
                      columnCount={getColsCount(width)}
                      rowHeight={CARD_HEIGHT}
                      rowCount={Math.ceil(
                        projects.totalCount / getColsCount(width)
                      )}
                      list={chunk(getColsCount(width))(projects.items)}
                      cellRenderer={({
                        columnIndex,
                        key,
                        rowIndex,
                        style,
                      }: {
                        columnIndex: number,
                        key: string,
                        rowIndex: number,
                        /* $FlowFixMe: Added when we enabled unclear-type lint
                         * rule */
                        style: Object,
                      }) => {
                        const colsCount = getColsCount(width);
                        const index = rowIndex * colsCount + columnIndex;
                        const project = projects.items[index];

                        return project ? (
                          <Pad key={key} style={style}>
                            <ProjectCard
                              title={`${project.name} ${
                                project.type === 'PRO' ? '(PRO)' : ''
                              }`}
                              to={getPath({
                                id: project.id,
                                type: project.type,
                              })}
                              lastEditedAt={
                                new Date(project.timestamps.updatedAt)
                              }
                              subEntitiesCount={
                                project.type === 'APPS'
                                  ? project.analyses.totalCount
                                  : project.portfolios.totalCount
                              }
                              subEntitiesLabel={
                                project.type === 'APPS'
                                  ? 'analysis'
                                  : 'portfolio'
                              }
                              sharedWith={project.sharedWith}
                              ownerName={
                                project.owner && project.owner.fullName
                              }
                              canShare={canShare(me)}
                              canModify={canModify({
                                project,
                                me,
                              })}
                              isPublic={project.public}
                              showPublicProjects={showPublicProjects}
                              onOpenShareManager={() => {
                                setShareManagerState(true);
                                setShareManagerProjectId(project.id);
                              }}
                              onDelete={evt => {
                                evt.preventDefault();
                                history.push(
                                  `/apps/projects/${project.id}/delete`
                                );
                              }}
                            />
                          </Pad>
                        ) : index < projects.totalCount ? (
                          <Pad key={key} style={style}>
                            <ProjectCard.Skeleton />
                          </Pad>
                        ) : (
                          <Ghost key={key} style={style} />
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              ) : (
                <GridSkeleton />
              )}
            </ListContainer>
          )}
        </Main>
      </Container>
    </Scaffhold>
  );
};

export default Projects;
