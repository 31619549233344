// @flow
import React, { useState } from 'react';
import { Logo as RCLogo, ContextMenu } from '@quid-private/react-components';
import { Button, Icon } from '@quid/react-core';
import { ThemeProvider } from '@quid/theme';
import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/core';
import ProductSection from './ProductSection';
import { getQuidProUrl } from 'modules/util';
import useAccessControl from 'utils/useAccessControl';
import quidProIcon from './img/Network.svg';
import quidAppsIcon from './img/Barchart.svg';
import { proUseCases, appsUseCases } from './useCases';
import BREAKPOINT from 'utils/breakpoint';
import Live2020Banner from './Banner/Live2020Banner';
import { useIntegrationContext } from 'components/IntegrationContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.gray6};
`;

const NavBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  background-color: ${props => props.theme.colors.gray5};
  padding: 0 5px;
  postion: relative;
  box-shadow: 0 0px 5px #000;
  z-index: 1;
`;

const Logo = styled(RCLogo)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;

const ProductSectionContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  @media (max-width: ${BREAKPOINT.TABLET}px) {
    flex-direction: column;
  }
  isolation: isolate;
`;

const Menu = styled(ContextMenu.Menu)`
  width: 140px;
`;

const DividerContainer = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINT.TABLET}px) {
    display: none;
  }
`;
const Divider = styled.div`
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.gray5};
  height: 90%;
  box-shadow: 1px 0px 0px ${props => props.theme.colors.black};
`;

const LogoutMenuItem = styled(ContextMenu.Item)`
  border-top: 1px solid ${props => props.theme.colors.gray5};
`;

const StyledContextMenu = styled(ContextMenu)`
  z-index: 3;
`;

export const SettingsMenu = () => {
  const helpUrl = 'https://nbq360.netbasequid.com';

  return (
    <Menu data-theme="light">
      <ContextMenu.Item
        tall
        onClick={() => window.open(`${helpUrl}/Quid_Pro`, '_blank')}
      >
        Help
      </ContextMenu.Item>
      <ContextMenu.Item
        tall
        onClick={() =>
          window.open('https://quid.com/privacy-policy/', '_blank')
        }
      >
        Privacy Policy
      </ContextMenu.Item>
      <ContextMenu.Item
        tall
        onClick={() =>
          window.open('https://quid.com/terms-and-conditions/', '_blank')
        }
      >
        Terms & Conditions
      </ContextMenu.Item>
      <LogoutMenuItem
        tall
        onClick={() => (window.location.href = '/auth/logout')}
      >
        Log Out
      </LogoutMenuItem>
    </Menu>
  );
};

const Home = () => {
  const [isSettingsMenuOpen, setSettingsMenuState] = useState(false);
  const { loadingUserAccess, hasAppsAccess, hasProAccess } = useAccessControl();
  const { LaunchDarkly } = useIntegrationContext();
  const showBanner = LaunchDarkly.flag('show-home-banner', false);

  return (
    <ThemeProvider theme="dark">
      <Container>
        <Global
          styles={css`
            html,
            body,
            #root {
              height: 100%;
            }
          `}
        />
        <NavBarContainer>
          <StyledContextMenu
            menu={<SettingsMenu />}
            isOpen={isSettingsMenuOpen}
            placement="bottom-end"
            popperModifiers={{
              preventOverflow: { enabled: false },
              hide: { enabled: false },
            }}
            onToggle={setSettingsMenuState}
          >
            <Button>
              <Icon name="gear" />
            </Button>
          </StyledContextMenu>
          <Logo />
        </NavBarContainer>
        {showBanner && <Live2020Banner />}
        <ProductSectionContainer>
          <ProductSection
            isLoading={loadingUserAccess}
            productTitle="Pro"
            productDescription="Start with one of our built-in datasets or upload your own data to examine any strategic question in depth."
            hasAccessURL={getQuidProUrl()}
            hasAccessText="Launch Pro"
            noAccessURL="https://quid.com/quid-in-action"
            noAccessText="Request Demo"
            hasAccess={hasProAccess}
            useCases={proUseCases}
            productImage={quidProIcon}
          />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <ProductSection
            isLoading={loadingUserAccess}
            productTitle="Apps"
            productDescription="Choose a question and get automated insights, powered by contextual Artificial Intelligence."
            hasAccessLink="/apps/projects"
            hasAccessText="Launch Apps"
            noAccessURL="https://quid.com/quid-apps"
            noAccessText="Sign-up"
            hasAccess={hasAppsAccess}
            useCases={appsUseCases}
            productImage={quidAppsIcon}
          />
        </ProductSectionContainer>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
