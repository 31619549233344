// @flow
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

declare var process: {
  env: {
    PUBLIC_URL: string,
    NODE_ENV: string,
  },
};

type Config = {
  // flowlint-next-line unclear-type:warn
  onWaiting: any => void,
  // flowlint-next-line unclear-type:warn
  onActivated: any => void,
  // flowlint-next-line unclear-type:warn
  onUpdate: any => void,
  // flowlint-next-line unclear-type:warn
  onSuccess: any => void,
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker &&
          navigator.serviceWorker.ready.then(() => {
            // eslint-disable-next-line no-console
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://goo.gl/SC7cgQ'
            );
          });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker &&
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        if (registration.waiting) {
          if (config.onWaiting) {
            config.onWaiting(registration.waiting);
          }
        }
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (!installingWorker) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'activated') {
              // At this point, the new service worker replaced the old
              // one on all the open tabs, but the tabs are still running
              // on the old shell code.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              // eslint-disable-next-line no-console
              console.log(
                'A service worker has been activated; please refresh.'
              );
              if (config.onActivated) {
                config.onActivated(registration);
              }
            } else if (installingWorker.state === 'installed') {
              if (
                navigator.serviceWorker &&
                navigator.serviceWorker.controller
              ) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in your web app.
                // eslint-disable-next-line no-console
                console.log('New content is available; please refresh.');

                // Execute callback
                if (config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                // eslint-disable-next-line no-console
                console.log('Content is cached for offline use.');

                // Execute callback
                if (config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error during service worker registration:', error);
      });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      const contentType = response.headers.get('content-type');
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        (contentType && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker &&
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator && navigator.serviceWorker) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
