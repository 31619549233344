// @flow
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Snackbar, SnackbarStack } from '@quid-private/react-components';
import { Button } from '@quid/react-core';
import { ERROR_TYPE } from 'utils/errors';
import { useServiceWorker } from 'components/ServiceWorkerContext';
import useStateUpdater from 'utils/useStateUpdater';
import useDocumentHidden from 'utils/useDocumentHidden';
import { NetworkStatusNotifier } from 'modules/apollo';
import { FlashMessageContext } from 'context/FlashMessageContext';
const NetworkErrorsSnackbars = () => {
  const [dismissedErrors, dismissError] = useStateUpdater<
    { [string]: boolean },
    string
  >({}, dismissedErrors => id => ({
    ...dismissedErrors,
    [id]: true,
  }));
  return (
    <NetworkStatusNotifier
      render={({ errors = [] } = {}) =>
        errors
          .filter(error => error.type === ERROR_TYPE.ERROR)
          .map(({ id, message }) => (
            <Snackbar
              key={id}
              stacked
              open={!dismissedErrors[id]}
              importance="warning"
              timeout={5e3}
              onTimeout={() => dismissError(id)}
            >
              {message}
            </Snackbar>
          ))
      }
    />
  );
};

const UPDATE_TIMEOUT = 30;

const AppUpdateSnackbar = () => {
  const { assetsUpdateReady, updateAssets } = useServiceWorker();
  const [updating, setUpdating] = useState(false);
  const hidden = useDocumentHidden();
  const [timeout, updateTimeout] = useState(UPDATE_TIMEOUT);
  const [canceled, setCanceled] = useState(false);
  const update = useCallback(() => {
    updateAssets();
    setUpdating(true);
  }, [updateAssets, setUpdating]);

  useEffect(() => {
    // We only want to start the timeout if the update snackbar is open
    if (!assetsUpdateReady) {
      return;
    }

    if (hidden) {
      // If the tab is not currently focused/active, we pause the countdown
      // because the user may not be aware of what's happening and not have
      // time to make a decision
      return;
    }

    // If the user canceled the timed update we don't want to proceed
    if (canceled) {
      return;
    }

    if (timeout > 0) {
      setTimeout(() => updateTimeout(timeout - 1), 1000);
    } else if (timeout === 0) {
      update();
    }
  }, [timeout, hidden, assetsUpdateReady, canceled, update]);

  return (
    <Snackbar
      stacked
      open={assetsUpdateReady}
      importance="action"
      timeout={0}
      renderAction={
        <>
          {!canceled && (
            <>
              <Button
                importance="secondary"
                onClick={() => setCanceled(true)}
                size="small"
                disabled={updating}
              >
                Update later
              </Button>{' '}
            </>
          )}

          <Button
            importance="primary"
            onClick={update}
            size="small"
            disabled={updating}
          >
            {updating ? 'Updating...' : 'Update now'}
          </Button>
        </>
      }
    >
      {!canceled
        ? `A new version of Quid will be installed ${
            timeout > 0 ? `in ${timeout} seconds` : 'now'
          }!`
        : 'A new version of Quid is available!'}
    </Snackbar>
  );
};

const FlashMessageSnackbar = () => {
  const { flashMessages } = useContext(FlashMessageContext);
  const [dismissedMessages, dismiss] = useStateUpdater<
    { [string]: boolean },
    string
  >({}, dismissedMessages => id => ({
    ...dismissedMessages,
    [id]: true,
  }));

  return flashMessages.map(({ message, id }) => {
    return (
      <Snackbar
        key={id}
        open={!dismissedMessages[id]}
        timeout={2e3}
        stacked
        importance="action"
        onTimeout={() => dismiss(id)}
      >
        <span data-context="flash-message">{message}</span>
      </Snackbar>
    );
  });
};

const SystemMessages = () => (
  <SnackbarStack>
    <FlashMessageSnackbar />
    <NetworkErrorsSnackbars />
    <AppUpdateSnackbar />
  </SnackbarStack>
);

export default SystemMessages;
