// @flow
import React from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Icon = styled.svg`
  width: 291px;
  height: 291px;
  & .fade-in {
    opacity: 0;
    animation: ${fadeIn} ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.8s;
  }
  & .fade-in.one {
    animation-delay: 0.2s;
  }
  & .fade-in.two {
    animation-delay: 0.4s;
  }
  & .fade-in.three {
    animation-delay: 0.6s;
  }
  & .fade-in.four {
    animation-delay: 0.8s;
  }
  & .fade-in.five {
    animation-delay: 1s;
  }
  & .fade-in.six {
    animation-delay: 1.2s;
  }
`;
const MacCoffeTasksIcon = () => (
  <Icon>
    <defs>
      <rect id="b" width="55" height="78" rx="4" />
      <filter
        id="a"
        width="223.6%"
        height="187.2%"
        x="-61.8%"
        y="-33.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="10"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.77254902 0 0 0 0 0.811764706 0 0 0 0 0.870588235 0 0 0 0.5 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="145.5" cy="145.5" r="130.5" fill="#FAFAFC" />
      <path
        fill="#FFF"
        d="M31 247c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 42 247h3c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 56 247h3c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 70 247h2c0-1.657 1.337-3 2.997-3h44.006a3 3 0 0 1 2.997 3h3c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h3c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h1.492c2.49 0 4.508 2.02 4.508 4.5 0 2.485-2.018 4.5-4.508 4.5H28.508A4.507 4.507 0 0 1 24 251.5c0-2.485 2.018-4.5 4.508-4.5H31z"
      />
      <path
        fill="#C5CFDE"
        fillRule="nonzero"
        d="M195 247h1.492c2.49 0 4.508 2.02 4.508 4.5 0 2.485-2.018 4.5-4.508 4.5H28.508A4.507 4.507 0 0 1 24 251.5c0-2.485 2.018-4.5 4.508-4.5H31c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 42 247h3c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 56 247h3c0-1.657 1.349-3 3.006-3h4.988A3.005 3.005 0 0 1 70 247h2c0-1.657 1.337-3 2.997-3h44.006a3 3 0 0 1 2.997 3h3c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h3c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3h4c0-1.657 1.349-3 3.006-3h4.988a3.005 3.005 0 0 1 3.006 3zm-2 0c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1h7zm-22 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm-138 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm14 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm14 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm66 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm14 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm15 0h7c0-.55-.451-1-1.006-1h-4.988c-.555 0-1.006.45-1.006 1zm-82 0h46a1 1 0 0 0-.997-1H74.997a.996.996 0 0 0-.997 1zm-47.95 5h172.9a2.507 2.507 0 0 0-2.458-3H28.508a2.504 2.504 0 0 0-2.458 3zm.386.906A2.51 2.51 0 0 0 28.508 254h167.984c.862 0 1.622-.434 2.074-1.094H26.436z"
      />
      <path
        fill="#F5F6FA"
        d="M11 191.008A4.005 4.005 0 0 1 15 187h26c2.209 0 4 1.791 4 4.008v21.994C45 221.837 37.831 229 28.996 229h-1.992C18.165 229 11 221.828 11 213.002v-21.994z"
      />
      <path
        fill="#EAECF2"
        d="M16.108 189s5.417 10.04 3.333 21.21c-1.155 6.185 3.056 16.79 3.463 16.79 1.199 0-9.236-1.767-10.39-10.605-1.155-8.837 0-26.533 0-26.533s0-.862 1.197-.862h2.397z"
      />
      <path
        fill="#F5F6FA"
        d="M13 194.016v3.011a4.505 4.505 0 0 0-5 4.475v9.995a4.501 4.501 0 0 0 5 4.475v2.012a7.59 7.59 0 0 1-.5.016c-4.142 0-7.5-3.362-7.5-7.495v-9.01c0-4.14 3.366-7.495 7.5-7.495.168 0 .335.006.5.016z"
      />
      <path
        fill="#C5CFDE"
        fillRule="nonzero"
        d="M13 191.008v21.994C13 220.726 19.273 227 27.004 227h1.992C36.728 227 43 220.731 43 213.002v-21.994A2.002 2.002 0 0 0 41 189H15c-1.103 0-2 .899-2 2.008zm-2 6.249v-3.107c-3.418.696-6 3.72-6 7.345v9.01c0 3.893 2.98 7.102 6.787 7.461a15.873 15.873 0 0 1-.538-2.142A4.504 4.504 0 0 1 8 211.497v-9.994a4.508 4.508 0 0 1 3-4.246zm0 2.245c-.607.457-1 1.184-1 2v9.995a2.5 2.5 0 0 0 1.008 2.009 16.253 16.253 0 0 1-.008-.504v-13.5zm1.61 20.497l-.11.001c-5.244 0-9.5-4.255-9.5-9.495v-9.01c0-4.731 3.47-8.657 8-9.377v-1.11A4.005 4.005 0 0 1 15 187h26c2.209 0 4 1.791 4 4.008v21.994C45 221.837 37.831 229 28.996 229h-1.992c-6.327 0-11.796-3.675-14.394-9zm17.004-58.01a1 1 0 0 1 .292-1.978c.896.132 2.182.51 3.482 1.277 2.234 1.317 3.612 3.349 3.612 6.15 0 2.769-1.642 3.86-5.964 5.258l-.726.234c-3.915 1.275-5.31 2.318-5.31 4.625 0 2.633.949 4.136 2.552 4.904a5.647 5.647 0 0 0 2.587.539l.028-.002a1 1 0 1 1 .146 1.995l-.096.005a7.438 7.438 0 0 1-1.306-.075 7.572 7.572 0 0 1-2.223-.658c-2.297-1.1-3.688-3.304-3.688-6.708 0-3.498 2.014-5.003 6.69-6.526l.73-.237c3.494-1.129 4.58-1.85 4.58-3.354 0-2.027-.962-3.444-2.628-4.427a8.56 8.56 0 0 0-1.92-.822 6.766 6.766 0 0 0-.838-.2z"
      />
      <g>
        <path
          fill="#FFF"
          d="M225.882 243h18.824S256 243 256 236.29c0-6.711-11.294-10.29-20.706-10.29-9.412 0-19.294 4.921-19.294 9.842s2.824 7.158 9.882 7.158z"
        />
        <path
          fill="#C5CFDE"
          fillRule="nonzero"
          d="M235.294 225c11.424 0 21.706 4.456 21.706 11.29 0 3.48-2.458 5.67-6.362 6.83-1.303.387-2.687.633-4.069.77-.483.048-.931.079-1.335.096-.247.01-.426.014-.528.014h-18.824c-7.456 0-10.882-2.533-10.882-8.158 0-4.737 7.337-9.193 15.615-10.475-2.832-5.62-9.895-10.262-24.003-17.08-1.053-.508-4.92-2.36-5.764-2.768a241.223 241.223 0 0 1-5.526-2.737c-4.182-2.15-7.272-3.993-9.64-5.83-1.826-1.416-2.968-2.964-3.438-4.629-1.2-4.253 1.915-8.513 7.704-12.668a53.094 53.094 0 0 1 5.278-3.31 53.353 53.353 0 0 1 1.739-.92c.317-.16.547-.271.673-.33l4.488-.095-3.64 1.905a51.362 51.362 0 0 0-2.295 1.19 51.131 51.131 0 0 0-5.077 3.185c-5.152 3.698-7.831 7.36-6.945 10.5.345 1.222 1.23 2.422 2.739 3.592 2.25 1.747 5.247 3.533 9.329 5.632 1.575.81 3.274 1.648 5.48 2.713.841.406 4.709 2.26 5.765 2.77 15.052 7.273 22.325 12.137 25.227 18.627.86-.075 1.724-.114 2.585-.114zm-9.412 17h18.824c.074 0 .226-.003.443-.012.368-.016.78-.044 1.223-.088 1.265-.125 2.528-.35 3.696-.697 3.154-.937 4.932-2.522 4.932-4.914 0-5.271-9.27-9.289-19.706-9.289-9.01 0-18.294 4.698-18.294 8.842 0 4.367 2.423 6.158 8.882 6.158z"
        />
        <path
          fill="#F5F6FA"
          d="M217.028 235.238s8.827 2.339 18.23 2.475c9.403.137 16.387-1.795 19.14-3.713 0 0 .959 1.604.456 3.3-.502 1.697-1.677 2.444-3.646 3.301-4.723 2.057-8.512 1.238-15.494 1.238-6.193 0-13.672.413-16.407-1.238-2.734-1.65-2.279-5.363-2.279-5.363z"
        />
      </g>
      <g transform="translate(46 34)">
        <rect
          width="201"
          height="141"
          x="1"
          y="1"
          fill="#FFF"
          stroke="#C5CFDE"
          strokeWidth="2"
          rx="18"
        />
        <path fill="#FFF" d="M78.462 142h47.076L132 165H72z" />
        <path fill="#E0E4EC" d="M79.095 144h46.32l.66 3.012L77.12 151.1z" />
        <path
          fill="#FFF"
          d="M68 166.5c0-1.38 1.12-2.5 2.504-2.5h61.992a2.496 2.496 0 0 1 2.504 2.5c0 1.38-1.12 2.5-2.504 2.5H70.504A2.496 2.496 0 0 1 68 166.5z"
        />
        <path
          fill="#C5CFDE"
          fillRule="nonzero"
          d="M78.18 143H17.994C8.056 143 0 134.94 0 124.998V18.002C0 8.06 8.052 0 17.993 0h167.014C194.944 0 203 8.06 203 18.002v106.996c0 9.942-8.052 18.002-17.993 18.002H125.82l5.9 21h.777a2.496 2.496 0 0 1 2.504 2.5c0 1.38-1.12 2.5-2.504 2.5H70.504A2.496 2.496 0 0 1 68 166.5c0-1.38 1.12-2.5 2.504-2.5h1.777l5.9-21zM70 166.5c0 .28.22.5.504.5h61.992c.28 0 .504-.224.504-.5 0-.28-.22-.5-.504-.5H70.504a.502.502 0 0 0-.504.5zm54.023-22.5H79.977l-5.338 19h54.722l-5.338-19zM2 18.002v106.996C2 133.836 9.16 141 17.993 141h167.014c8.835 0 15.993-7.163 15.993-16.002V18.002C201 9.164 193.84 2 185.007 2H17.993C9.158 2 2 9.163 2 18.002zM12 12v106h180V12H12zm-2-2h184v110H10V10z"
        />
        <path fill="#F5F6FA" d="M12 12h91v106H12z" />
        <path
          fill="#C5CFDE"
          fillRule="nonzero"
          d="M101.5 133a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 2a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z"
        />
        <circle cx="102" cy="5" r="1" fill="#7E8FA4" />
        <path
          fill="#BFC9DA"
          fillRule="nonzero"
          d="M140.115 81.002a4 4 0 1 1-4.063 4.647C126.367 83.829 120.67 76.914 119 65a4 4 0 0 1-1.803-7.57c.416-8.345 3.313-14.284 8.064-18.62.683-.624 1.661-1.362 2.695-2.096a7 7 0 1 1 10.9-4.294c7.13 1.648 12.656 4.167 16.566 7.552 4.828-4.233 10.719-7.94 17.67-11.121a4 4 0 1 1 .335.946c-6.788 3.113-12.54 6.729-17.26 10.85 3.526 3.334 5.582 7.456 6.159 12.36a7 7 0 0 1 6.276 9.328c13.527 6.635 19.403 15.55 17.551 26.678a9 9 0 1 1-12.944 6.054c-13.904-5.015-24.413-15.586-31.526-31.688-1.456 5.414-1.978 11.287-1.567 17.623zm-4.097 3.621a4.003 4.003 0 0 1 3.101-3.526c-.452-6.928.193-13.32 1.936-19.174-2.967-7.055-5.308-15.13-7.023-24.222A7 7 0 0 1 132 38a6.97 6.97 0 0 1-3.157-.75 25.12 25.12 0 0 0-2.909 2.298c-4.482 4.092-7.244 9.665-7.718 17.529a4 4 0 0 1 1.774 7.8c1.596 11.396 6.925 17.946 16.028 19.746zm25.3-31.59c-.57-4.646-2.534-8.55-5.901-11.72-6.466 5.857-10.898 12.709-13.302 20.559 6.997 16.448 17.45 27.183 31.363 32.228a9.004 9.004 0 0 1 11.727-5.513c1.632-10.542-4.01-18.966-17.003-25.338a7 7 0 1 1-6.884-10.216zM134.98 37.336c1.617 8.634 3.798 16.336 6.541 23.107 2.517-7.519 6.9-14.12 13.148-19.8-3.781-3.245-9.139-5.664-16.086-7.261a7.023 7.023 0 0 1-3.603 3.954z"
        />
      </g>
      <g transform="translate(221 134)">
        <use fill="#000" filter="url(#a)" href="#b" />
        <use fill="#F5F6FA" href="#b" />
        <path
          fill="#C5CFDE"
          fillRule="nonzero"
          d="M2 4.002v69.996C2 75.102 2.897 76 4 76h47c1.1 0 2-.9 2-2.002V4.002A2.003 2.003 0 0 0 51 2H4c-1.1 0-2 .9-2 2.002zm-2 0A4.006 4.006 0 0 1 4 0h47c2.21 0 4 1.794 4 4.002v69.996A4.006 4.006 0 0 1 51 78H4c-2.21 0-4-1.794-4-4.002V4.002z"
        />
        <g transform="translate(2 2)">
          <path
            className="fade-in four"
            fill="#7E8FA4"
            d="M13 52h7v1h-7zM21 52h9v1h-9zM32 52h7v1h-7z"
          />
          <path className="fade-in four" fill="#C5CFDE" d="M13 48h26v1H13z" />
          <path
            className="fade-in three"
            fill="#7E8FA4"
            d="M13 41h7v1h-7zM21 41h7v1h-7zM30 41h14v1H30z"
          />
          <path className="fade-in two" fill="#C5CFDE" d="M13 38h12v1H13z" />
          <path
            className="fade-in two"
            fill="#7E8FA4"
            d="M13 29h19v1H13zM33 29h5v1h-5z"
          />
          <path className="fade-in two" fill="#C5CFDE" d="M13 26h21v1H13z" />
          <path className="fade-in one" fill="#7E8FA4" d="M13 18h30v1H13z" />
          <path className="fade-in one" fill="#C5CFDE" d="M13 15h21v1H13z" />
          <path
            className="fade-in two"
            fill="#1ED7D1"
            d="M10.833 13.51a.402.402 0 0 1 .102.546L7.23 19.719c-.112.17-.286.281-.458.281a.726.726 0 0 1-.486-.222l-2.175-2.255a.405.405 0 0 1 0-.557l.537-.557a.37.37 0 0 1 .537 0l1.414 1.466 3.079-4.704a.373.373 0 0 1 .527-.103l.628.442z"
          />
          <path
            className="fade-in three"
            fill="#1ED7D1"
            d="M10.833 13.51m0 11a.402.402 0 0 1 .102.546L7.23 30.719c-.112.17-.286.281-.458.281a.726.726 0 0 1-.486-.222l-2.175-2.255a.405.405 0 0 1 0-.557l.537-.557a.37.37 0 0 1 .537 0l1.414 1.466 3.079-4.704a.373.373 0 0 1 .527-.103l.628.442z"
          />
          <path
            className="fade-in four"
            fill="#1ED7D1"
            d="M10.833 13.51m0 11m0 11a.402.402 0 0 1 .102.546L7.23 41.719c-.112.17-.286.281-.458.281a.726.726 0 0 1-.486-.222l-2.175-2.255a.405.405 0 0 1 0-.557l.537-.557a.37.37 0 0 1 .537 0l1.414 1.466 3.079-4.704a.373.373 0 0 1 .527-.103l.628.442z"
          />
          <path
            className="fade-in five"
            fill="#1ED7D1"
            d="M10.833 13.51m0 11m0 11m0 12a.402.402 0 0 1 .102.546L7.23 53.719c-.112.17-.286.281-.458.281a.726.726 0 0 1-.486-.222l-2.175-2.255a.405.405 0 0 1 0-.557l.537-.557a.37.37 0 0 1 .537 0l1.414 1.466 3.079-4.704a.373.373 0 0 1 .527-.103l.628.442z"
          />
          <path
            className="fade-in six"
            fill="#1ED7D1"
            d="M10.833 13.51m0 11m0 11m0 12m0 11a.402.402 0 0 1 .102.546L7.23 64.719c-.112.17-.286.281-.458.281a.726.726 0 0 1-.486-.222l-2.175-2.255a.405.405 0 0 1 0-.557l.537-.557a.37.37 0 0 1 .537 0l1.414 1.466 3.079-4.704a.373.373 0 0 1 .527-.103l.628.442z"
          />
          <path
            className="fade-in five"
            fill="#7E8FA4"
            d="M13 63h7v1h-7zM21 63h23v1H21z"
          />
          <path className="fade-in five" fill="#C5CFDE" d="M13 60h12v1H13z" />
          <path
            fill="#E0E4EC"
            d="M0 1.997C0 .894.896 0 2 0h47a2 2 0 0 1 2 1.997V9H0V1.997z"
          />
          <circle cx="12.5" cy="3.5" r="1.5" fill="#7E8FA4" />
          <circle cx="8.5" cy="3.5" r="1.5" fill="#7E8FA4" />
          <circle cx="4.5" cy="3.5" r="1.5" fill="#7E8FA4" />
        </g>
      </g>
    </g>
  </Icon>
);
export default MacCoffeTasksIcon;
