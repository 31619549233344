// @flow

// Analyses
export function analysesUrl({ projectId }: { projectId: string }) {
  return `/apps/projects/${projectId}/analyses`;
}

export function deleteAnalysisUrl({
  projectId,
  analysisId,
}: {
  projectId: string,
  analysisId: string,
}) {
  return `/apps/projects/${projectId}/analyses/${analysisId}/delete`;
}

// Projects
export function projectUrl({
  projectId,
  type = 'APPS',
}: {
  projectId: string,
  type: 'PRO' | 'APPS',
}) {
  return `/apps/projects/${projectId}/${
    type === 'APPS' ? 'analyses' : 'portfolios'
  }`;
}

export function projectsUrl() {
  return '/apps/projects/';
}

// New Analyses
export function newAnalysisUrl({ projectId }: { projectId: string }) {
  return `${analysesUrl({ projectId })}/new`;
}

export function viewOutputUrl({
  projectId,
  analysisId,
  outputId,
}: {
  projectId: string,
  analysisId: string,
  outputId: string,
}) {
  return `/apps/projects/${projectId}/analyses/${analysisId}/view/${outputId}`;
}
